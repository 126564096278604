import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core';
import { translations } from '../../../../common/utils/constants/translations';
import { Constants } from '../../../../common/utils/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  constructor(private snackBar: MatSnackBar,
			  private lang: TranslateService) {}

  success(msg: string) {
    this.snackBar.open(this.lang.instant(msg), undefined, {
      duration: Constants.NOTIFY_SUCCESS,
    })
  }

  error(msg: string) {
    this.snackBar.open(this.lang.instant(msg), this.lang.instant(translations.notify_2), {
      duration: Constants.NOTIFY_ERROR,
    })
  }
}
