import { Injectable } from '@angular/core';

export enum LocalStorageKeys {
	lang = 'lang'
}

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	readonly keys = LocalStorageKeys

	getItem(key: LocalStorageKeys | string, def = null): string | null {
		const item = localStorage.getItem(key)
		return item ?? def
	}

	setItem(key: LocalStorageKeys | string, value: any): void {
		localStorage.setItem(key, value)
	}

	removeItem(key: LocalStorageKeys | string): void {
		localStorage.removeItem(key)
	}

	hasItem(key: LocalStorageKeys | string): boolean {
		return localStorage.getItem(key) !== null
	}
}
