import { createAction, props } from '@ngrx/store'
import { HaypostOffice } from '../../../../../../../common/models/haypost-office'
import { errorProps } from '../../../../../../../common/utils/store/types'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'

export const haypostLoad = createAction('[Haypost] load', cityIdProps)

export const haypostLoadSuccess = createAction('[Haypost] load sccess', props<{ data: HaypostOffice[] }>())

export const haypostLoadError = createAction('[Haypost] load error', errorProps)

export const haypostRemove = createAction('[Haypost] remove')
