import { HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { CitiesListResponse } from 'common/models/cities-list-response'
import { Country } from 'common/models/country'
import { RegionsListResponse } from 'common/models/regions-list-response'
import { RequestService } from 'common/services/request.service'
import { disableJwtInterception } from 'ngx-customapp-jwt'
import { EMPTY } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class GeoSearchService {
  constructor(private requestService: RequestService) {}

  getCountries() {
    return this.requestService.post<unknown, Country[]>(
      '/api/delivery/countries',
      {},
      {
        context: new HttpContext().set(disableJwtInterception, true),
      },
    )
  }

  searchRegion(name: string, options: { countryId: number }) {
    return this.requestService.post<unknown, RegionsListResponse>(
      '/api/delivery/regions',
      {},
      {
        context: new HttpContext().set(disableJwtInterception, true),
        params: {
          page: 0,
          page_size: 100,
          country_id: options.countryId,
          region_name: name,
        },
      },
    )
  }

  searchCity(name: string, options: { regionId?: number; countryId: number }) {
    if (name.length < 2) {
      return EMPTY;
    }

    return this.requestService.post<unknown, CitiesListResponse>(
      'api/delivery/cities',
      {},
      {
        context: new HttpContext().set(disableJwtInterception, true),
        params: {
          country_id: options.countryId,
          region_id: options.regionId ?? 0,
          page_size: 60,
          city_name: name,
          page: 0,
        },
      },
    )
  }
}
