import { createAction, props } from '@ngrx/store'
import { CdekOffice } from '../../../../../../../common/models/cdek-office'
import { errorProps } from '../../../../../../../common/utils/store/types'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'

export const cdekLoad = createAction('[Cdek] load', cityIdProps)

export const cdekLoadSuccess = createAction('[Cdek] load success', props<{ data: CdekOffice[] }>())

export const cdekLoadError = createAction('[Cdek] load error', errorProps)

export const cdekRemove = createAction('[Cdek] remove')
