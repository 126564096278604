import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { Address } from 'common/models/address'
import { AddressListResponse } from '../../../../../common/models/address-list-response'

export const getAddresses = createActionGroup({
  source: 'Get my addresses',
  events: {
    start: emptyProps(),
    success: props<AddressListResponse>(),
    error: props<{ error: string }>(),
  },
})

export const addAddress = createActionGroup({
  source: 'Add address',
  events: {
    start: props<{ address: Address }>(),
    success: props<AddressListResponse>(),
    error: props<{ error: string }>(),
  },
})

export const updateAddress = createActionGroup({
  source: 'Update address',
  events: {
    start: props<{ id: number; address: Address }>(),
    success: props<AddressListResponse>(),
    error: props<{ error: string }>(),
  },
})

export const deleteAddress = createActionGroup({
  source: 'Delete address',
  events: {
    start: props<{ id: number }>(),
    success: props<AddressListResponse>(),
    error: props<{ error: string }>(),
  },
})

export const setSelectedAddress = createAction('[Set selected address]', props<{ address: Address }>())

export const allErrors = [deleteAddress.error, updateAddress.error, addAddress.error, getAddresses.error]
