import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { PersonalVolumeService } from '../api/personal-volume.service'
import { getPersonalAccruals } from './personal-volume.actions'

@Injectable()
export class PersonalVolumeEffects {
  constructor(private actions$: Actions, private personalVolumeService: PersonalVolumeService) {}

  loadPersonalValueAccruals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPersonalAccruals.start),
      mergeMap(({ pagination }) =>
        this.personalVolumeService.getPersonalVolume(pagination).pipe(
          map(response => getPersonalAccruals.success(response)),
          catchError(error => of(getPersonalAccruals.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
