import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { errorProps } from '../../../../common/utils/store/types';

export const setLoadingAction = createAction('[LOADING] Set loading', props<{ isLoading: boolean }>())

export const activateLoadingForAWhile = createActionGroup({
	source: '[LOADING] Activate loading for a while',
	events: {
		start: emptyProps(),
		success: emptyProps(),
		error: errorProps
	}
})
