import { createReducer, on } from '@ngrx/store'
import { ReviewsListResponse } from '../../../../../common/models/reviews-list-response'
import { reviewListAdd, reviewListClear, reviewListLoad } from './review-list.actions'

export const reviewListFeatureKey = 'reviewList'

export interface State {
  data?: ReviewsListResponse
  isLoading?: boolean
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(reviewListLoad.start, reviewListAdd.start, state => ({
    ...state,
    isLoading: true,
  })),
  on(reviewListLoad.success, (state, { data }) => ({
    ...state,
    isLoading: false,
    data,
  })),
  on(reviewListAdd.success, (state, { data }) => ({
    ...state,
    isLoading: false,
    data: state.data && {
      ...state.data,
      reviews: state.data?.reviews && [...state.data.reviews, data],
    },
  })),
  on(reviewListClear, () => initialState),
)
