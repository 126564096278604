// Trigger animation cards array
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

export const tilesAnimation = trigger('tilesAnimation', [
	// Transition from any state to any state
	transition('* => *', [
		// Initially the all cards are not visible
		query(':enter', style({ opacity: 0 }), { optional: true }),

		// Each card will appear sequentially with the delay of 0.10s
		query(
			':enter',
			stagger('5ms', [
				animate(
					'400ms ease-in',
					keyframes([
						style({ opacity: 0, transform: 'scale(0.90)', offset: 0 }),
						style({ opacity: 0.1, transform: 'scale(0.95)', offset: 0.5 }),
						style({ opacity: 1, transform: 'scale(1)', offset: 1 }),
					])
				),
			]),
			{ optional: true }
		),

		query(
			':leave',
			stagger('0ms', [
				animate(
					'200ms ease-out',
					keyframes([
						style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
						style({ opacity: 0.1, transform: 'scale(0.95)', offset: 0.5 }),
						style({ opacity: 0, transform: 'scale(0.90)', offset: 1 }),
					])
				)
			]),
			{ optional: true }
		),
	]),
]);

export const tileAnimation = trigger('tileAnimation', [
	transition(':enter', [
		animate(
			600,
			keyframes([
				style({ opacity: 0, transform: 'scale(0.90)', offset: 0 }),
				style({ opacity: 0.1, transform: 'scale(0.95)', offset: 0.5 }),
				style({ opacity: 1, transform: 'scale(1)', offset: 1 }),
			])
		),
	]),
	transition(':leave', [
		animate(
			600,
			keyframes([
				style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
				style({ opacity: 0.1, transform: 'scale(0.95)', offset: 0.5 }),
				style({ opacity: 0, transform: 'scale(0.90)', offset: 1 }),
			])
		),
	]),
]);
