import { createActionGroup, props } from '@ngrx/store'
import { TicketData } from '../api/create-ticket.service'
import { Ticket } from 'common/models/ticket'

export const sendTicket = createActionGroup({
  source: 'Create ticket',
  events: {
    start: props<{
      ticketData: TicketData
    }>(),
    success: props<Ticket>(),
    error: props<{ error: string }>(),
  },
})

export const allErrors = [sendTicket.error]
