import { Injectable } from '@angular/core'
import { Address } from 'common/models/address'
import { RequestService } from 'common/services/request.service'
import { AddressListResponse } from '../../../../../common/models/address-list-response'

@Injectable({ providedIn: 'root' })
export class AddressService {
  constructor(private requestService: RequestService) {}

  private url = '/api/user_address'

  getAddresses() {
    return this.requestService.post<unknown, AddressListResponse>(`${this.url}/get`, {})
  }

  add(newAddress: Address) {
    return this.requestService.post<Address, AddressListResponse>(`${this.url}/add`, newAddress)
  }

  update(id: number, newAddress: Address) {
    return this.requestService.post<Address, AddressListResponse>(`${this.url}/update`, { ...newAddress, id })
  }

  delete(id: number) {
    return this.requestService.post<{ id: number }, AddressListResponse>(`${this.url}/delete`, { id })
  }
}
