import { Component, HostListener } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'app-scroll-to-top',
	templateUrl: './scroll-to-top.component.html',
	standalone: true,
	imports: [CommonModule],
	styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent {
	showScrollToTop = false

	@HostListener('window:scroll', [])
	onWindowScroll() {
		this.showScrollToTop = window.scrollY > 1000
	}

	scrollToTop() {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}
}
