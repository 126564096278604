import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'
import { createAction, props } from '@ngrx/store'
import { FivePostOffice } from '../../../../../../../common/models/five-post-office'
import { errorProps } from '../../../../../../../common/utils/store/types'

export const fivePostLoad = createAction('[FivePost] load', cityIdProps)

export const fivePostLoadSuccess = createAction('[FivePost] load success', props<{ data: FivePostOffice[] }>())

export const fivePostLoadError = createAction('[FivePost] load error', errorProps)

export const fivePostRemove = createAction('[FivePost] remove')
