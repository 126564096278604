import { createAction, props } from '@ngrx/store'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'
import { RuPostOffice } from '../../../../../../../common/models/ru-post-office'
import { errorProps } from '../../../../../../../common/utils/store/types'

export const rupostLoad = createAction('[RuPost] load', cityIdProps)

export const rupostLoadSuccess = createAction('[RuPost] load success', props<{ data: RuPostOffice[] }>())

export const rupostLoadError = createAction('[RuPost] load error', errorProps)

export const rupostRemove = createAction('[RuPost] remove')
