import { Injectable } from '@angular/core'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class BecomePartnerService {
  constructor(private requestService: RequestService) {}

  becomePartner() {
    return this.requestService.post<unknown, void>('/api/account/make_partner', {})
  }
}
