import { createReducer, on } from '@ngrx/store'
import { loginErrored, loginSucceed } from 'common/store/jwt/jwt.actions'

export const authFeatureKey = 'auth'

export interface State {
  isAuthorized: boolean
}

export const initialState: State = {
  isAuthorized: false,
}

export const reducer = createReducer(
  initialState,
  on(loginSucceed, () => ({ isAuthorized: true })),
  on(loginErrored, () => ({ isAuthorized: false })),
)
