import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { EwaOffice } from 'common/models/ewa-office'

@Injectable({
  providedIn: 'root',
})

export class ContactPageService {
  constructor(private http: HttpClient) {}

  public getContactsData(): Observable<EwaOffice[]> {
    return this.http.get<EwaOffice[]>(`api/contacts/office?type=main,pvz`) // ограничемся пвз и штабом
  }
}
