<app-dialog-header (closeClick)="close()"> <h5 class="h5">УСПЕХ</h5> </app-dialog-header>
<mat-dialog-content>
  <app-dialog-body>
    <div class="content">
      <mat-icon class="success-icon">done</mat-icon>
      <p class="body2 description" [innerHTML]="data.description"></p>
    </div>
  </app-dialog-body>
</mat-dialog-content>

<div mat-dialog-actions class="controls">
  <app-dialog-footer>
    <button class="button" (click)="close()" app-button variant="outlined" size="large">ЗАКРЫТЬ</button>
  </app-dialog-footer>
</div>
