import { createAction } from '@ngrx/store'
import { boxberryLoadError } from './boxberry/boxberry.actions'
import { kgpostLoadError } from './kgpost/kgpost.actions'
import { fargoLoadError } from './fargo/fargo.actions'
import { dpdLoadError } from './dpd/dpd.actions'
import { rupostLoadError } from './rupost/rupost.actions'
import { cdekLoadError } from './cdek/cdek.actions'
import { europostLoadError } from './europost/europost.actions'
import { haypostLoadError } from './haypost/haypost.actions'
import { kazpostLoadError } from './kazpost/kazpost.actions'
import { fivePostLoadError } from './five-post/five-post.actions'

export const deliveryOfficeRemoveAll = createAction('[DeliveryOffice] remove all')

export const allErrors = [
	boxberryLoadError,
	kgpostLoadError,
	fargoLoadError,
	dpdLoadError,
	rupostLoadError,
	cdekLoadError,
	europostLoadError,
	haypostLoadError,
	kazpostLoadError,
	fivePostLoadError
]
