import { createAction, createActionGroup, props } from '@ngrx/store'
import { ReviewsListResponse } from '../../../../../common/models/reviews-list-response'
import { errorProps } from '../../../../../common/utils/store/types'
import { CreateReviewRequest } from '../../../../../common/models/create-review-request'
import { Review } from '../../../../../common/models/review'

export const reviewListLoad = createActionGroup({
  source: '[ReviewList] load',
  events: {
    start: props<{ productId: number; page: number; pageSize: number }>(),
    success: props<{ data: ReviewsListResponse }>(),
    error: errorProps,
  },
})

export const reviewListAdd = createActionGroup({
  source: '[ReviewList] add',
  events: {
    start: props<{ req: CreateReviewRequest }>(),
    success: props<{ data: Review }>(),
    error: errorProps,
  },
})

export const reviewListClear = createAction('[ReviewList] clear')

export const allErrors = [reviewListLoad.error, reviewListAdd.error]
