import { Pipe, PipeTransform } from '@angular/core'
import { Item } from '../../../../../common/models/item'

// Возвращает timestamp, когда итем появится на складе (то есть предзаказ начнет доставляться) или undefined
export const isItemAvailableForPreorder = (item: Item | undefined, stockId: number): number | undefined =>
  item?.availability?.find(s => s.stock_id === stockId && s.is_visible === true)?.preorder

@Pipe({
  name: 'appIsItemAvailableForPreorder',
  standalone: true,
})
export class IsItemAvailableForPreorderPipe implements PipeTransform {
  transform(item: Item | undefined, stockId: number): number | undefined {
    return isItemAvailableForPreorder(item, stockId)
  }
}
