import { createReducer, on } from '@ngrx/store'
import { Currency } from 'common/models/currency'
import { Country } from 'common/models/country'
import { loadConfig, loadCurrencies, loadCountries } from './config.actions'
import { Config } from 'common/models/config'

export const featureKey = 'global-config'

export interface State {
  currencies: Currency[] | null
  config: Config | null
  countries: Country[] | null
}

export const initialState: State = {
  currencies: null,
  config: null,
  countries: null,
}

export const reducer = createReducer(
  initialState,
  on(loadConfig.success, (state, { config }) => ({ ...state, config })),
  on(loadCountries.success, (state, { countries }) => ({ ...state, countries })),
  on(loadCurrencies.success, (state, { currencies }) => ({ ...state, currencies })),
)
