import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { becomePartner } from './become-partner.actions'
import { BecomePartnerService } from '../api/become-partner.service'
import { logoutSucceed } from 'common/store/jwt/jwt.actions'
import { NotifyService } from 'client/src/app/services/notify.service'

@Injectable()
export class BecomePartnerEffects {
  constructor(
    private actions$: Actions,
    private becomePartnerService: BecomePartnerService,
    private notifyService: NotifyService,
  ) {}

  becomePartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(becomePartner.start),
      mergeMap(() =>
        this.becomePartnerService.becomePartner().pipe(
          map(() => becomePartner.success()),
          catchError(error => of(becomePartner.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  /** When becomePartner suceed, the user is logged out. So we call logoutSuceed action */
  updateUserLoginInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(becomePartner.success),
      mergeMap(() => {
        this.notifyService.success('Вы стали партнером. Пожалуйста, войдите в аккаунт')
        return of(logoutSucceed())
      }),
    ),
  )
}
