import { Pipe, PipeTransform } from '@angular/core'
import { Grade } from '../../models/grade'
import { GRADE_LONG_ENG } from '../../utils/minion/grades'
import { RoleUser } from '../../models/role-user'

export const gradeLong = (grade: Grade | undefined | null, role: RoleUser | undefined | null) =>
  !role || role === RoleUser.RolePartner ? (grade ? GRADE_LONG_ENG[grade] : '') : 'Client'

@Pipe({
  name: 'appGradeLong',
  standalone: true,
})
export class GradeLongPipe implements PipeTransform {
  transform(grade: Grade | undefined | null, role?: RoleUser | undefined | null): string {
    return gradeLong(grade, role)
  }
}
