import { createActionGroup, props } from '@ngrx/store'
import { EventListResponse } from 'common/models/event-list-response'

export const getActiveEventsList = createActionGroup({
  source: 'Active events page load',
  events: {
    start: props<{ props?: string }>(),
    success: props<{ response: EventListResponse }>(),
    error: props<{ error: string }>(),
  },
})

export const allErrors = [getActiveEventsList.error]