import { Injectable } from '@angular/core'
import { RequestService } from '../../../../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { FargoOffice } from 'common/models/fargo-office'

@Injectable({
	providedIn: 'root'
})
export class FargoService {
	constructor(private request: RequestService) {}

	load(city_id: number): Observable<FargoOffice[]> {
		return this.request.post('/api/delivery/fargo_offices', undefined, {
			params: {
				city_id
			}
		})
	}
}
