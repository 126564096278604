export const translations = {
	products: 'products',
	about: 'about',
	production: 'production',
	delivery: 'delivery',
	events: 'events',
	locations: 'locations',
	certification: 'certification',
	help: 'help',
	contacts: 'contacts',
	policies: 'policies',
	all_rights_reserved: 'all_rights_reserved',
	become_a_partner: 'become_a_partner',
	x_to_y_daily_eest: 'x_to_y_daily_eest',
	this_website_is_using_cookie: 'this_website_is_using_cookie',
	by_continuing_to_use_our_site_you_consent_to_our_use_of_cookies:
		'by_continuing_to_use_our_site_you_consent_to_our_use_of_cookies',
	ok: 'ok',
	learn_more_about: 'learn_more_about',
	personal_data_company_policy: 'personal_data_company_policy',
	ewa_official_website: 'ewa_official_website',
	open_channel_vk: 'open_channel_vk',
	open_channel_telegram: 'open_channel_telegram',
	open_channel_youtube: 'open_channel_youtube',
	become_a_company_partner: 'become_a_company_partner',
	send_request_and_we_will_contact_you: 'send_request_and_we_will_contact_you',
	your_name: 'your_name',
	phone_number: 'phone_number',
	email: 'email',
	city: 'city',
	mentor_id: 'mentor_id',
	in_case_you_have_it: 'in_case_you_have_it',
	i_agree_with_the_companys_policy_regarding_personal_data:
		'i_agree_with_the_companys_policy_regarding_personal_data',
	i_consent_to_the_processing_of_personal_data: 'i_consent_to_the_processing_of_personal_data',
	apply: 'apply',
	ewa: 'ewa',
	young_and_ambitious_company_with_innovative_partner_reward_system:
		'young_and_ambitious_company_with_innovative_partner_reward_system',
	ewa_product_official_site: 'ewa_product_official_site',
	favourites_ewa_product: 'favourites_ewa_product',
	save_your_favourite_products: 'save_your_favourite_products',
	online_store_ewa_product: 'online_store_ewa_product',
	catalogue_of_ewa_products_baa_nutrition_cosmetics_accessories: 'catalogue_of_ewa_products_baa_nutrition_cosmetics_accessories',
	we_are_young_and_ambitious_company_established_in_2022_by_mlm_entrepreneurs: 'we_are_young_and_ambitious_company_established_in_2022_by_mlm_entrepreneurs',
	online_store_ewa_product_partner_id: 'online_store_ewa_product_partner_id',
	about_company_ewa_product: 'about_company_ewa_product',
	contacts_ewa_product: 'contacts_ewa_product',
	headquarters_and_order_pickup_locations_ewa_product: 'headquarters_and_order_pickup_locations_ewa_product',
	business_partner_registration_ewa_product: 'business_partner_registration_ewa_product',
	make_mlm_profitable_again: 'make_mlm_profitable_again',
	become_a_business_partner_ewa_product: 'become_a_business_partner_ewa_product',
	events_ewa_product: 'events_ewa_product',
	country: 'country',
	ru: 'ru',
	am: 'am',
	by: 'by',
	kz: 'kz',
	kg: 'kg',
	uz: 'uz',
	ua: 'ua',
	knowledge_base: 'knowledge_base',
	contact_company: 'contact_company',
	online_store: 'online_store',
	client_bonus: 'client_bonus',
	about_the_company: 'about_the_company',
	business: 'business',
	news: 'news',
	personal_office: 'personal_office',
	warehouse: 'warehouse',
	controlled_foreign_company: 'controlled_foreign_company',
	my_id: 'my_id',
	account: 'account',
	logout: 'logout',
	log_in: 'log_in',
	settings: 'settings',
	my_orders: 'my_orders',
	exit_from_cfc: 'exit_from_cfc',
	marketing: 'marketing',
	ads: 'ads',
	product: 'product',
	education: 'education',
	logistics: 'logistics',
	promo: 'promo',
	support: 'support',
	we_have_already_built_your_business: 'we_have_already_built_your_business',
	your_own_business_without_investments: 'your_own_business_without_investments',
	the_size_of_the_market: 'the_size_of_the_market',
	start_your_journey: 'start_your_journey',
	earn: 'earn',
	with_interest: 'with_interest',
	about_1: 'about_1',
	about_2: 'about_2',
	about_3: 'about_3',
	about_4: 'about_4',
	about_5: 'about_5',
	about_6: 'about_6',
	about_7: 'about_7',
	about_8: 'about_8',
	about_9: 'about_9',
	about_10: 'about_10',
	about_11: 'about_11',
	vitaly_koval: 'vitaly_koval',
	about_12: 'about_12',
	about_13: 'about_13',
	about_14: 'about_14',
	about_15: 'about_15',
	about_16: 'about_16',
	about_17: 'about_17',
	about_18: 'about_18',
	about_19: 'about_19',
	about_20: 'about_20',
	about_21: 'about_21',
	about_22: 'about_22',
	about_23: 'about_23',
	about_24: 'about_24',
	about_25: 'about_25',
	about_26: 'about_26',
	about_27: 'about_27',
	about_28: 'about_28',
	about_29: 'about_29',
	business_1: 'business_1',
	business_2: 'business_2',
	business_3: 'business_3',
	business_4: 'business_4',
	business_5: 'business_5',
	business_6: 'business_6',
	business_7: 'business_7',
	business_8: 'business_8',
	business_9: 'business_9',
	business_10: 'business_10',
	business_11: 'business_11',
	business_12: 'business_12',
	business_13: 'business_13',
	business_14: 'business_14',
	business_15: 'business_15',
	business_16: 'business_16',
	business_17: 'business_17',
	business_18: 'business_18',
	business_19: 'business_19',
	business_20: 'business_20',
	business_21: 'business_21',
	business_22: 'business_22',
	business_23: 'business_23',
	business_24: 'business_24',
	business_25: 'business_25',
	business_26: 'business_26',
	business_27: 'business_27',
	business_28: 'business_28',
	business_29: 'business_29',
	business_30: 'business_30',
	business_31: 'business_31',
	business_32: 'business_32',
	business_33: 'business_33',
	business_34: 'business_34',
	business_35: 'business_35',
	business_36: 'business_36',
	business_37: 'business_37',
	business_38: 'business_38',
	business_39: 'business_39',
	business_40: 'business_40',
	business_41: 'business_41',
	business_42: 'business_42',
	business_43: 'business_43',
	business_44: 'business_44',
	business_45: 'business_45',
	business_46: 'business_46',
	business_47: 'business_47',
	business_48: 'business_48',
	business_49: 'business_49',
	business_50: 'business_50',
	business_51: 'business_51',
	business_52: 'business_52',
	business_53: 'business_53',
	business_54: 'business_54',
	business_55: 'business_55',
	business_56: 'business_56',
	business_57: 'business_57',
	business_58: 'business_58',
	business_59: 'business_59',
	business_60: 'business_60',
	business_61: 'business_61',
	business_62: 'business_62',
	business_63: 'business_63',
	business_64: 'business_64',
	business_65: 'business_65',
	business_66: 'business_66',
	business_67: 'business_67',
	business_68: 'business_68',
	business_69: 'business_69',
	business_70: 'business_70',
	business_71: 'business_71',
	business_72: 'business_72',
	business_73: 'business_73',
	business_74: 'business_74',
	business_75: 'business_75',
	business_76: 'business_76',
	business_77: 'business_77',
	business_78: 'business_78',
	business_79: 'business_79',
	business_80: 'business_80',
	business_81: 'business_81',
	business_82: 'business_82',
	business_83: 'business_83',
	business_84: 'business_84',
	business_85: 'business_85',
	business_86: 'business_86',
	business_87: 'business_87',
	business_88: 'business_88',
	business_89: 'business_89',
	business_90: 'business_90',
	business_91: 'business_91',
	business_92: 'business_92',
	business_93: 'business_93',
	business_94: 'business_94',
	business_95: 'business_95',
	business_96: 'business_96',
	business_97: 'business_97',
	auth_1: 'auth_1',
	auth_2: 'auth_2',
	auth_3: 'auth_3',
	auth_4: 'auth_4',
	auth_5: 'auth_5',
	auth_6: 'auth_6',
	auth_7: 'auth_7',
	auth_8: 'auth_8',
	auth_9: 'auth_9',
	auth_10: 'auth_10',
	auth_11: 'auth_11',
	auth_12: 'auth_12',
	auth_13: 'auth_13',
	events_1: 'events_1',
	events_2: 'events_2',
	events_3: 'events_3',
	['все']: 'все',
	['хиты']: 'хиты',
	['акции']: 'акции',
	['чаи']: 'чаи',
	['косметика']: 'косметика',
	['дом']: 'дом',
	['аксессуары']: 'аксессуары',
	['наборы для активации']: 'наборы для активации',
	['новинки']: 'новинки',
	['БАД']: 'БАД',
	['биохакинг']: 'биохакинг',
	['похудение']: 'похудение',
	['функциональное питание']: 'функциональное питание',
	['чай']: 'чай',
	['уход за лицом']: 'уход за лицом',
	['уход за телом']: 'уход за телом',
	['уход за волосами']: 'уход за волосами',
	categories: 'categories',
	bestsellers: 'bestsellers',
	shop_1: 'shop_1',
	shop_2: 'shop_2',
	shop_3: 'shop_3',
	shop_4: 'shop_4',
	shop_5: 'shop_5',
	shop_6: 'shop_6',
	shop_7: 'shop_7',
	shop_8: 'shop_8',
	shop_9: 'shop_9',
	shop_10: 'shop_10',
	shop_11: 'shop_11',
	shop_12: 'shop_12',
	shop_13: 'shop_13',
	shop_14: 'shop_14',
	shop_15: 'shop_15',
	shop_16: 'shop_16',
	shop_17: 'shop_17',
	shop_18: 'shop_18',
	shop_19: 'shop_19',
	shop_20: 'shop_20',
	shop_21: 'shop_21',
	shop_22: 'shop_22',
	shop_23: 'shop_23',
	shop_24: 'shop_24',
	shop_25: 'shop_25',
	shop_26: 'shop_26',
	shop_27: 'shop_27',
	shop_28: 'shop_28',
	shop_29: 'shop_29',
	shop_30: 'shop_30',
	shop_31: 'shop_31',
	shop_32: 'shop_32',
	shop_33: 'shop_33',
	shop_34: 'shop_34',
	shop_35: 'shop_35',
	shop_36: 'shop_36',
	shop_37: 'shop_37',
	shop_38: 'shop_38',
	shop_39: 'shop_39',
	shop_40: 'shop_40',
	shop_41: 'shop_41',
	shop_42: 'shop_42',
	shop_43: 'shop_43',
	shop_44: 'shop_44',
	shop_45: 'shop_45',
	shop_46: 'shop_46',
	shop_47: 'shop_47',
	shop_48: 'shop_48',
	shop_49: 'shop_49',
	shop_50: 'shop_50',
	shop_51: 'shop_51',
	shop_52: 'shop_52',
	shop_53: 'shop_53',
	shop_54: 'shop_54',
	shop_55: 'shop_55',
	shop_56: 'shop_56',
	shop_57: 'shop_57',
	shop_58: 'shop_58',
	shop_59: 'shop_59',
	shop_60: 'shop_60',
	shop_61: 'shop_61',
	shop_62: 'shop_62',
	shop_63: 'shop_63',
	shop_64: 'shop_64',
	shop_65: 'shop_65',
	shop_66: 'shop_66',
	shop_67: 'shop_67',
	shop_68: 'shop_68',
	shop_69: 'shop_69',
	shop_70: 'shop_70',
	shop_71: 'shop_71',
	shop_72: 'shop_72',
	shop_73: 'shop_73',
	shop_74: 'shop_74',
	shop_75: 'shop_75',
	shop_76: 'shop_76',
	shop_77: 'shop_77',
	shop_78: 'shop_78',
	shop_79: 'shop_79',
	shop_80: 'shop_80',
	shop_81: 'shop_81',
	shop_82: 'shop_82',
	shop_83: 'shop_83',
	shop_84: 'shop_84',
	shop_85: 'shop_85',
	shop_86: 'shop_86',
	shop_87: 'shop_87',
	shop_88: 'shop_88',
	shop_89: 'shop_89',
	shop_90: 'shop_90',
	shop_91: 'shop_91',
	shop_92: 'shop_92',
	shop_93: 'shop_93',
	shop_94: 'shop_94',
	shop_95: 'shop_95',
	shop_96: 'shop_96',
	shop_97: 'shop_97',
	shop_98: 'shop_98',
	shop_99: 'shop_99',
	shop_100: 'shop_100',
	shop_101: 'shop_101',
	shop_102: 'shop_102',
	shop_103: 'shop_103',
	shop_104: 'shop_104',
	shop_105: 'shop_105',
	shop_106: 'shop_106',
	shop_107: 'shop_107',
	shop_108: 'shop_108',
	shop_109: 'shop_109',
	shop_110: 'shop_110',
	shop_111: 'shop_111',
	shop_112: 'shop_112',
	shop_113: 'shop_113',
	shop_114: 'shop_114',
	shop_115: 'shop_115',
	shop_116: 'shop_116',
	shop_117: 'shop_117',
	policies_1: 'policies_1',
	policies_2: 'policies_2',
	policies_3: 'policies_3',
	policies_4: 'policies_4',
	policies_5: 'policies_5',
	policies_6: 'policies_6',
	policies_7: 'policies_7',
	policies_8: 'policies_8',
	policies_9: 'policies_9',
	policies_10: 'policies_10',
	policies_11: 'policies_11',
	policies_12: 'policies_12',
	policies_13: 'policies_13',
	policies_14: 'policies_14',
	policies_15: 'policies_15',
	policies_16: 'policies_16',
	policies_17: 'policies_17',
	policies_18: 'policies_18',
	policies_19: 'policies_19',
	policies_20: 'policies_20',
	policies_21: 'policies_21',
	policies_22: 'policies_22',
	policies_23: 'policies_23',
	policies_24: 'policies_24',
	policies_25: 'policies_25',
	policies_26: 'policies_26',
	policies_27: 'policies_27',
	policies_28: 'policies_28',
	policies_29: 'policies_29',
	policies_30: 'policies_30',
	policies_31: 'policies_31',
	policies_32: 'policies_32',
	policies_33: 'policies_33',
	policies_34: 'policies_34',
	policies_35: 'policies_35',
	policies_36: 'policies_36',
	policies_37: 'policies_37',
	policies_38: 'policies_38',
	policies_39: 'policies_39',
	policies_40: 'policies_40',
	policies_41: 'policies_41',
	policies_42: 'policies_42',
	policies_43: 'policies_43',
	policies_44: 'policies_44',
	policies_45: 'policies_45',
	policies_46: 'policies_46',
	policies_47: 'policies_47',
	policies_48: 'policies_48',
	policies_49: 'policies_49',
	policies_50: 'policies_50',
	policies_51: 'policies_51',
	policies_52: 'policies_52',
	policies_53: 'policies_53',
	policies_54: 'policies_54',
	policies_55: 'policies_55',
	policies_56: 'policies_56',
	policies_57: 'policies_57',
	policies_58: 'policies_58',
	policies_59: 'policies_59',
	policies_60: 'policies_60',
	policies_61: 'policies_61',
	policies_62: 'policies_62',
	policies_63: 'policies_63',
	policies_64: 'policies_64',
	policies_65: 'policies_65',
	policies_66: 'policies_66',
	policies_67: 'policies_67',
	policies_68: 'policies_68',
	policies_69: 'policies_69',
	policies_70: 'policies_70',
	policies_71: 'policies_71',
	policies_72: 'policies_72',
	policies_73: 'policies_73',
	policies_74: 'policies_74',
	policies_75: 'policies_75',
	policies_76: 'policies_76',
	policies_77: 'policies_77',
	policies_78: 'policies_78',
	policies_79: 'policies_79',
	policies_80: 'policies_80',
	policies_81: 'policies_81',
	policies_82: 'policies_82',
	policies_83: 'policies_83',
	policies_84: 'policies_84',
	policies_85: 'policies_85',
	policies_86: 'policies_86',
	policies_87: 'policies_87',
	policies_88: 'policies_88',
	policies_89: 'policies_89',
	policies_90: 'policies_90',
	policies_91: 'policies_91',
	policies_92: 'policies_92',
	policies_93: 'policies_93',
	policies_94: 'policies_94',
	policies_95: 'policies_95',
	policies_96: 'policies_96',
	policies_97: 'policies_97',
	policies_98: 'policies_98',
	policies_99: 'policies_99',
	policies_100: 'policies_100',
	policies_101: 'policies_101',
	policies_102: 'policies_102',
	policies_103: 'policies_103',
	policies_104: 'policies_104',
	policies_105: 'policies_105',
	policies_106: 'policies_106',
	policies_107: 'policies_107',
	policies_108: 'policies_108',
	policies_109: 'policies_109',
	policies_110: 'policies_110',
	policies_111: 'policies_111',
	policies_112: 'policies_112',
	policies_113: 'policies_113',
	policies_114: 'policies_114',
	policies_115: 'policies_115',
	policies_116: 'policies_116',
	policies_117: 'policies_117',
	policies_118: 'policies_118',
	policies_119: 'policies_119',
	policies_120: 'policies_120',
	policies_121: 'policies_121',
	policies_122: 'policies_122',
	policies_123: 'policies_123',
	policies_124: 'policies_124',
	policies_125: 'policies_125',
	policies_126: 'policies_126',
	policies_127: 'policies_127',
	policies_128: 'policies_128',
	policies_129: 'policies_129',
	policies_130: 'policies_130',
	policies_131: 'policies_131',
	policies_132: 'policies_132',
	policies_133: 'policies_133',
	policies_134: 'policies_134',
	policies_135: 'policies_135',
	policies_136: 'policies_136',
	policies_137: 'policies_137',
	policies_138: 'policies_138',
	policies_139: 'policies_139',
	policies_140: 'policies_140',
	policies_141: 'policies_141',
	policies_142: 'policies_142',
	policies_143: 'policies_143',
	policies_144: 'policies_144',
	policies_145: 'policies_145',
	policies_146: 'policies_146',
	policies_147: 'policies_147',
	policies_148: 'policies_148',
	policies_149: 'policies_149',
	policies_150: 'policies_150',
	policies_151: 'policies_151',
	policies_152: 'policies_152',
	policies_153: 'policies_153',
	policies_154: 'policies_154',
	policies_155: 'policies_155',
	policies_156: 'policies_156',
	policies_157: 'policies_157',
	policies_158: 'policies_158',
	policies_159: 'policies_159',
	policies_160: 'policies_160',
	policies_161: 'policies_161',
	policies_162: 'policies_162',
	policies_163: 'policies_163',
	policies_164: 'policies_164',
	policies_165: 'policies_165',
	policies_166: 'policies_166',
	policies_167: 'policies_167',
	policies_168: 'policies_168',
	policies_169: 'policies_169',
	policies_170: 'policies_170',
	policies_171: 'policies_171',
	policies_172: 'policies_172',
	policies_173: 'policies_173',
	policies_174: 'policies_174',
	policies_175: 'policies_175',
	policies_176: 'policies_176',
	policies_177: 'policies_177',
	policies_178: 'policies_178',
	policies_179: 'policies_179',
	policies_180: 'policies_180',
	policies_181: 'policies_181',
	policies_182: 'policies_182',
	policies_183: 'policies_183',
	policies_184: 'policies_184',
	policies_185: 'policies_185',
	policies_186: 'policies_186',
	policies_187: 'policies_187',
	policies_188: 'policies_188',
	policies_189: 'policies_189',
	policies_190: 'policies_190',
	policies_191: 'policies_191',
	policies_192: 'policies_192',
	policies_193: 'policies_193',
	policies_194: 'policies_194',
	policies_195: 'policies_195',
	policies_196: 'policies_196',
	policies_197: 'policies_197',
	policies_198: 'policies_198',
	policies_199: 'policies_199',
	policies_200: 'policies_200',
	policies_201: 'policies_201',
	policies_202: 'policies_202',
	policies_203: 'policies_203',
	policies_204: 'policies_204',
	policies_205: 'policies_205',
	policies_206: 'policies_206',
	policies_207: 'policies_207',
	policies_208: 'policies_208',
	policies_209: 'policies_209',
	policies_210: 'policies_210',
	policies_211: 'policies_211',
	policies_212: 'policies_212',
	policies_213: 'policies_213',
	policies_214: 'policies_214',
	warehouse_1: 'warehouse_1',
	warehouse_2: 'warehouse_2',
	warehouse_3: 'warehouse_3',
	['Грозный']: 'Грозный',
	['Минск']: 'Минск',
	['Москва']: 'Москва',
	['Новосибирск']: 'Новосибирск',
	['Санкт-Петербург']: 'Санкт-Петербург',
	['Якутск']: 'Якутск',
	['Ереван']: 'Ереван',
	['Минск (транзитный)']: 'Минск (транзитный)',
	['Астана']: 'Астана',
	['Кокшетау']: 'Кокшетау',
	['Усть-Каменогорск']: 'Усть-Каменогорск',
	['Бишкек']: 'Бишкек',
	['Ташкент']: 'Ташкент',
	['Киев']: 'Киев',
	['Барнаул']: 'Барнаул',
	['Владивосток']: 'Владивосток',
	['Иркутск']: 'Иркутск',
	['Калининград']: 'Калининград',
	['Краснодар']: 'Краснодар',
	['Красноярск']: 'Красноярск',
	['Москва (ПВЗ)']: 'Москва (ПВЗ)',
	['Норильск']: 'Норильск',
	['Орёл']: 'Орёл',
	['Россия-Беларусь (транзит)']: 'Россия-Беларусь (транзит)',
	['Томск']: 'Томск',
	['Чита']: 'Чита',
	number: 'number',
	invalid_format: 'invalid_format',
	['1.']: '1.',
	['1.1.']: '1.1.',
	['1.2.']: '1.2.',
	['1.3.']: '1.3.',
	['1.4.']: '1.4.',
	['1.5.']: '1.5.',
	['1.6.']: '1.6.',
	['1.7.']: '1.7.',
	['1.8.']: '1.8.',
	['1.9.']: '1.9.',
	['2.']: '2.',
	['2.1.']: '2.1.',
	['2.2.']: '2.2.',
	['2.3.']: '2.3.',
	['2.4.']: '2.4.',
	['2.5.']: '2.5.',
	['3.']: '3.',
	['3.1.']: '3.1.',
	['3.2.']: '3.2.',
	['3.3.']: '3.3.',
	['3.4.']: '3.4.',
	['3.5.']: '3.5.',
	['4.']: '4.',
	['4.1.']: '4.1.',
	['4.2.']: '4.2.',
	['4.2.1.']: '4.2.1.',
	['4.2.2.']: '4.2.2.',
	['4.2.3.']: '4.2.3.',
	['4.2.4.']: '4.2.4.',
	['4.2.5.']: '4.2.5.',
	['4.2.6.']: '4.2.6.',
	['4.2.7.']: '4.2.7.',
	['4.2.8.']: '4.2.8.',
	['4.2.9.']: '4.2.9.',
	['4.2.10.']: '4.2.10.',
	['4.3.']: '4.3.',
	['4.4.']: '4.4.',
	['4.5.']: '4.5.',
	['5.']: '5.',
	['5.1.']: '5.1.',
	['5.2.']: '5.2.',
	['5.3.']: '5.3.',
	['5.4.']: '5.4.',
	['5.5.']: '5.5.',
	['6.']: '6.',
	['7.']: '7.',
	['8.']: '8.',
	['9.']: '9.',
	['10.']: '10.',
	['11.']: '11.',
	['12.']: '12.',
	['13.']: '13.',
	['14.']: '14.',
	['15.']: '15.',
	['16.']: '16.',
	['17.']: '17.',
	['18.']: '18.',
	['19.']: '19.',
	['20.']: '20.',
	['21.']: '21.',
	['22.']: '22.',
	['23.']: '23.',
	['24.']: '24.',
	['25.']: '25.',
	['26.']: '26.',
	['27.']: '27.',
	['28.']: '28.',
	['29.']: '29.',
	['30.']: '30.',
	['31.']: '31.',
	['32.']: '32.',
	['33.']: '33.',
	['34.']: '34.',
	['35.']: '35.',
	['36.']: '36.',
	['37.']: '37.',
	['38.']: '38.',
	['39.']: '39.',
	['40.']: '40.',
	['41.']: '41.',
	['42.']: '42.',
	['43.']: '43.',
	['44.']: '44.',
	['45.']: '45.',
	['46.']: '46.',
	['47.']: '47.',
	['48.']: '48.',
	['49.']: '49.',
	['50.']: '50.',
	['51.']: '51.',
	['52.']: '52.',
	['53.']: '53.',
	['54.']: '54.',
	['55.']: '55.',
	['56.']: '56.',
	['57.']: '57.',
	['58.']: '58.',
	['59.']: '59.',
	['60.']: '60.',
	['61.']: '61.',
	['62.']: '62.',
	['63.']: '63.',
	['64.']: '64.',
	['65.']: '65.',
	['66.']: '66.',
	['67.']: '67.',
	['68.']: '68.',
	['69.']: '69.',
	['70.']: '70.',
	['71.']: '71.',
	['72.']: '72.',
	['73.']: '73.',
	['74.']: '74.',
	['75.']: '75.',
	['76.']: '76.',
	['77.']: '77.',
	['78.']: '78.',
	['79.']: '79.',
	['80.']: '80.',
	['81.']: '81.',
	['82.']: '82.',
	['83.']: '83.',
	['84.']: '84.',
	['85.']: '85.',
	['86.']: '86.',
	['87.']: '87.',
	['88.']: '88.',
	['89.']: '89.',
	['90.']: '90.',
	['91.']: '91.',
	['92.']: '92.',
	['93.']: '93.',
	['94.']: '94.',
	['95.']: '95.',
	['96.']: '96.',
	['97.']: '97.',
	['98.']: '98.',
	['99.']: '99.',
	['100.']: '100.',
	'A.': 'A.',
	'B.': 'B.',
	'C.': 'C.',
	'D.': 'D.',
	'E.': 'E.',
	'F.': 'F.',
	'G.': 'G.',
	'H.': 'H.',
	'I.': 'I.',
	'J.': 'J.',
	'K.': 'K.',
	'L.': 'L.',
	'M.': 'M.',
	'N.': 'N.',
	'O.': 'O.',
	'P.': 'P.',
	'Q.': 'Q.',
	'R.': 'R.',
	'S.': 'S.',
	'T.': 'T.',
	'U.': 'U.',
	'V.': 'V.',
	'W.': 'W.',
	'X.': 'X.',
	'Y.': 'Y.',
	'Z.': 'Z.',
	ewa_1: 'ewa_1',
	ewa_2: 'ewa_2',
	ewa_3: 'ewa_3',
	ewa_4: 'ewa_4',
	ewa_5: 'ewa_5',
	ewa_6: 'ewa_6',
	ewa_7: 'ewa_7',
	ewa_8: 'ewa_8',
	ewa_9: 'ewa_9',
	ewa_10: 'ewa_10',
	ewa_11: 'ewa_11',
	ewa_12: 'ewa_12',
	ewa_13: 'ewa_13',
	ewa_14: 'ewa_14',
	ewa_15: 'ewa_15',
	ewa_16: 'ewa_16',
	ewa_17: 'ewa_17',
	ewa_18: 'ewa_18',
	ewa_19: 'ewa_19',
	ewa_20: 'ewa_20',
	ewa_21: 'ewa_21',
	header_1: 'header_1',
	header_2: 'header_2',
	header_3: 'header_3',
	header_4: 'header_4',
	header_5: 'header_5',
	header_6: 'header_6',
	header_7: 'header_7',
	header_8: 'header_8',
	header_9: 'header_9',
	header_10: 'header_10',
	header_11: 'header_11',
	header_12: 'header_12',
	header_13: 'header_13',
	header_14: 'header_14',
	header_15: 'header_15',
	header_16: 'header_16',
	header_17: 'header_17',
	notify_1: 'notify_1',
	notify_2: 'notify_2',
	validation_1: 'validation_1',
	validation_2: 'validation_2',
	validation_3: 'validation_3',
	slider_1: 'slider_1',
	slider_2: 'slider_2',
	slider_3: 'slider_3',
	slider_4: 'slider_4',
	download_catalog: 'download_catalog'
}
