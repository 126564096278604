import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { translations } from '../../../../common/utils/constants/translations';
import { TranslateService } from '@ngx-translate/core';

interface IMetaTag {
  name: string,
  content: string
}

interface IMetaProperty {
  property: string,
  content: string
}

interface ISeoModel {
  titleTranslationKey: string,
  metaTags: MetaTagWithTranslationKey[],
  metaProps: MetaPropertyWithTranslationKey[]
}

interface MetaTagWithTranslationKey {
  name: string,
  translationKey: string
}

interface MetaPropertyWithTranslationKey {
  property: string,
  translationKey: string
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
	  private titleService: Title,
	  private metaService: Meta,
	  private lang: TranslateService
  ) {}

  updateTitle(titleTranslationKey: string) {
    this.titleService.setTitle(this.lang.instant(titleTranslationKey));
  }

  updateMetaTags(tags: MetaTagWithTranslationKey[]) {
    tags.forEach(tag => {
		this.metaService.updateTag({ name: tag.name, content: this.lang.instant(tag.translationKey) })
	})
  }

  updateMetaProperty(props: MetaPropertyWithTranslationKey[]) {
    props.forEach(prop => {
		this.metaService.updateTag({ property: prop.property, content: this.lang.instant(prop.translationKey) })
	})
  }

  updateSeo(data: ISeoModel) {
    if (data.titleTranslationKey) {
      this.titleService.setTitle(this.lang.instant(data.titleTranslationKey))
    } else {
      this.titleService.setTitle(this.lang.instant(translations.ewa_product_official_site))
    }

    if (data.metaTags) {
      this.updateMetaTags(data.metaTags)
    } else {
      this.updateMetaTags([
        { name: "description", translationKey: translations.young_and_ambitious_company_with_innovative_partner_reward_system }
      ])
    }

    if (data.metaProps) {
      this.updateMetaProperty(data.metaProps)
    } else {
      this.updateMetaProperty([
        { property: "og:title", translationKey: translations.ewa_product_official_site },
        { property: "og:description", translationKey: translations.young_and_ambitious_company_with_innovative_partner_reward_system },
        { property: "og:url", translationKey: "https://ewaproduct.com" }
      ])
    }
  }
}
