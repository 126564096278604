import { createReducer, on } from '@ngrx/store'
import { ProductWithItems } from 'common/models/product-with-items'
import { getProduct, getProductsAndCategories, clearFavorites, setCategory, setSearchText, addFavorite, deleteFavorite } from './shop.actions'
import { createEntityAdapter, EntityState } from '@ngrx/entity'

export const shopFeatureKey = 'shop'

const selectProductId = (p: ProductWithItems) => p.id ?? 0

const sortProductByPos = (a: ProductWithItems, b: ProductWithItems) => (a.position ?? 0) - (b.position ?? 0)

export const productAdapter = createEntityAdapter({
  selectId: selectProductId,
  sortComparer: sortProductByPos,
})

export interface State {
  isLoading: boolean
  categories: string[]
  selectedCategory: string | null
  products: EntityState<ProductWithItems>
  selectedProduct: ProductWithItems | null
  isProductLoading: boolean
  searchText: string
  isProductVariationLoading: boolean
}

export const initialState: State = {
  isLoading: false,
  categories: [],
  selectedCategory: null,
  products: productAdapter.getInitialState(),
  selectedProduct: null,
  isProductLoading: false,
  searchText: "",
  isProductVariationLoading: false,
}

export const reducer = createReducer(
  initialState,

  on(setSearchText, (state, { searchText }) => ({
    ...state,
    searchText: searchText
  })),

  on(getProductsAndCategories.start, state => {
    return { ...state, isLoading: true }
  }),
  on(getProductsAndCategories.success, (state, { products, categories }) => {
    return { ...state, isLoading: false, products: productAdapter.setAll(products, state.products), categories }
  }),
  on(getProductsAndCategories.error, state => {
    return { ...state, isLoading: false }
  }),

  on(setCategory, (state, { category }) => {
    return { ...state, selectedCategory: category }
  }),

  on(getProduct.start, (state, { slug }) => {
    //const productToFind = state.products.entities[id] ?? null
    
    const products = Object.values(state.products.entities);
    const productToFind = products.find(product => product != undefined && product.slug === slug) ?? null;

    return { ...state, selectedProduct: productToFind, isProductLoading: true, isProductVariationLoading: true }
  }),
  on(getProduct.success, (state, { product }) => {
    return { ...state, selectedProduct: product, isProductLoading: false, isProductVariationLoading: false }
  }),
  on(getProduct.error, state => {
    return { ...state, isProductLoading: false, isProductVariationLoading: false }
  }),

  on(addFavorite.start, state => ({
    ...state,
    isLoading: true,
    isProductLoading: true,
  })),
  on(addFavorite.success, (state, { productId }) => {
    const isUpdatingSelectedProduct = state.selectedProduct?.id === productId;
    return {
      ...state,
      isLoading: false,
      isProductLoading: false,
      products: productAdapter.updateOne({
        id: productId,
        changes: { isFavorite: true }
      }, state.products),
      selectedProduct: isUpdatingSelectedProduct ? { ...state.selectedProduct, isFavorite: true } : state.selectedProduct
    }
  }),
  on(addFavorite.error, (state, { error }) => ({
    ...state,
    isLoading: false,
    isProductLoading: false,
  })),

  on(deleteFavorite.start, state => ({
    ...state,
    isLoading: true,
    isProductLoading: true,
  })),
  on(deleteFavorite.success, (state, { productId }) => {
    const isUpdatingSelectedProduct = state.selectedProduct?.id === productId;
    return {
      ...state,
      isLoading: false,
      isProductLoading: false,
      products: productAdapter.updateOne({
        id: productId,
        changes: { isFavorite: false }
      }, state.products),
      selectedProduct: isUpdatingSelectedProduct ? { ...state.selectedProduct, isFavorite: false } : state.selectedProduct
    }
  }),
  on(addFavorite.error, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),

  on(clearFavorites, (state) => {
    const updates = state.products.ids.map(id => ({
      id: typeof id === 'string' ? parseInt(id, 10) : id,
      changes: { isFavorite: false },
    })).filter(update => !isNaN(update.id)); 
  
    return {
      ...state,
      products: productAdapter.updateMany(updates, state.products),
      selectedProduct: { ...state.selectedProduct, isFavorite: false }
    };
  }),
)
