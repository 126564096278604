export const handleCommaSepList = (v: string | undefined | null): string[] => {
  if (!v) {
    return []
  }
  return v.split(',').map(s => s.trim())
}

export const handlePhone = (v: string | undefined | null): string | undefined => {
  if (!v) {
    return undefined
  }
  return v.replace(/\D/g, '')
}

export const handleLogin = (v: string | undefined | null): string | undefined => {
  if (!v) {
    return undefined
  }
  if (v.startsWith('+')) {
    return v.slice(1)
  }
  return v.trim()
}
