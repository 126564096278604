import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Region } from '../../../../../../common/models/region'
import { regionClear, regionLoad } from './region.actions'

export const regionFeatureKey = 'region'

export interface State extends EntityState<Region> {
  isLoading: boolean
  isLoaded: boolean
}

const sortByName = (a: Region, b: Region) => (a.name || '').localeCompare(b.name || '')

const selectId = (country: Region) => country.id!

export const adapter = createEntityAdapter<Region>({
  selectId,
  sortComparer: sortByName,
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false,
})

export const reducer = createReducer(
  initialState,
  on(regionLoad.start, state => ({
    ...state,
    isLoading: true,
  })),
  on(regionLoad.success, (state, { data }) =>
    adapter.setAll(data, {
      ...state,
      isLoading: false,
      isLoaded: true,
    }),
  ),
  on(regionLoad.error, state => ({
    ...state,
    isLoading: false,
  })),
  on(regionClear, () => initialState),
)
