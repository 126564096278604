import { CanActivateFn } from '@angular/router'
import { inject } from '@angular/core'
import { AppState } from '../../store/state'
import { Store } from '@ngrx/store'
import { JwtService } from 'ngx-customapp-jwt'
import { map, tap } from 'rxjs'
import { loginAgain } from '../../../../common/store/jwt/jwt.actions'
import { jwtToCredentials } from '../../../../common/utils/user/auth'
import { getCart } from '../../store/checkout/cart/cart.actions'

export const initJwtAuthGuard: CanActivateFn = () => {
  const store = inject(Store<AppState>)
  const jwtService = inject(JwtService)
  return jwtService.freshJwt(true).pipe(
    tap(jwt => {
      if (jwt?.accessToken) {
        // если есть токены, то нужно с ними запустить авторизацию
        // после успешной авторизации задиспатчится getCart и подгрузится корзина юзера
        store.dispatch(loginAgain({ credentials: jwtToCredentials(jwt.accessToken) }))
      } else {
        // загружает корзину, если чел не авторизован
        store.dispatch(getCart())
      }
    }),
    map(() => true),
  )
}
