import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ReviewService } from '../api/review.service'
import { reviewListAdd, reviewListLoad } from './review-list.actions'
import { catchError, map, mergeMap, of, tap } from 'rxjs'
import { NotifyService } from '../../../app/services/notify.service'

@Injectable()
export class ReviewListEffects {
  constructor(private actions$: Actions, private reviewService: ReviewService, private notify: NotifyService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewListLoad.start),
      mergeMap(({ productId, page, pageSize }) =>
        this.reviewService.load(productId, page, pageSize).pipe(
          map(data => reviewListLoad.success({ data })),
          catchError(error => of(reviewListLoad.error({ error }))),
        ),
      ),
    ),
  )

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewListAdd.start),
      mergeMap(({ req }) =>
        this.reviewService.add(req).pipe(
          map(data => reviewListAdd.success({ data })),
          catchError(error => of(reviewListAdd.error({ error }))),
        ),
      ),
    ),
  )

  showSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reviewListAdd.success),
        tap(() => {
          this.notify.success('Отзыв добавлен! Он будет опубликован после модерации')
        }),
      ),
    { dispatch: false },
  )
}
