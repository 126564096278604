import { createAction, createActionGroup, props } from '@ngrx/store'
import { Navigation } from 'common/models/navigation'
import { Order } from 'common/models/order'
import { OrdersByIntIdRequest } from '../../models/orders-by-int-id-request'
import { ConstraintsOrdersRequest } from 'common/models/constraints-orders-request'
import { GroupOrderRequest } from 'common/models/group-order-request'

export const PAGE_SIZE = 10

export const createGroupOrder = createActionGroup({
  source: '[Group Order] create',
  events: {
    start: props<{ req: GroupOrderRequest }>(),
    success: props<{ order: Order }>(),
    error: props<{ error: string }>(),
  },
})

export const cancelGroupOrder = createAction('cancel group order')
export const changeCreateOrderStep = createAction(
  'change step create order',
  props<{ step: number }>()
)

export const approveGroupOrder = createAction(
  'approve group order',
  props<{ approve: boolean }>()
)

export const loadGroupOrderInfo = createActionGroup({
  source: '[Group Order] info',
  events: {
    start: props<{ id: number }>(),
    success: props<{ order: Order }>(),
    error: props<{ error: string }>(),
  },
})

export const loadOrders = createActionGroup({
  source: '[Orders] load',
  events: {
    start: props<{ req: OrdersByIntIdRequest }>(),
    success: props<{ orders?: Order[]; navigation?: Navigation; int_id?: number }>(),
    error: props<{ error: string }>(),
  },
})

export const loadOrdersAdmin = createActionGroup({
  source: '[Orders] load admin ',
  events: {
    start: props<{ req: ConstraintsOrdersRequest }>(),
    success: props<{ orders?: Order[]; navigation?: Navigation }>(),
    error: props<{ error: string }>(),
  },
})
export const resetOrdersState = createAction('Reset orders state to default')

export const updateOrder = createAction('Update order in orders store', props<{ order: Order }>())

export const allErrors = [loadOrders.error, loadOrdersAdmin.error]
