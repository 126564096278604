import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import { breakpoints, Breakpoints } from '../../styles/shared/breakpoints'
import { BreakpointObserver } from '@angular/cdk/layout'

// to exclude case when screen width === breakpoint
const breakpointGap = 0.02

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  constructor(private bpObserver: BreakpointObserver) {}

  /**
   * Returns true, if the screen is smaller than bp
   * @param bp breakpoint label
   */
  down(bp: Breakpoints): Observable<boolean> {
    // same as scss mixins
    return this.bpObserver.observe(`(max-width: ${breakpoints[bp]}px)`).pipe(map(bpState => bpState.matches))
  }

  /**
   * Returns true, if the screen is larger that bp
   * @param bp breakpoint label
   */
  up(bp: Breakpoints): Observable<boolean> {
    return this.bpObserver
      .observe(`(min-width: ${breakpoints[bp] + breakpointGap}px)`)
      .pipe(map(bpState => bpState.matches))
  }
}
