import { Injectable } from '@angular/core'
import { AccrualsGroupVolumesHistoryRequest } from 'common/models/accruals-group-volumes-history-request'
import { AccrualsGroupVolumesListResponse } from 'common/models/accruals-group-volumes-list-response'
import { RequestService } from 'common/services/request.service'

export const pageSize = 100

@Injectable({ providedIn: 'root' })
export class GroupVolumeService {
  constructor(private requestService: RequestService) {}

  getGroupVolume(pagination: { page: number }, dateFilters?: AccrualsGroupVolumesHistoryRequest) {
    return this.requestService.post<AccrualsGroupVolumesHistoryRequest | undefined, AccrualsGroupVolumesListResponse>(
      'api/account/history/group_volumes',
      dateFilters,
      { params: { ...pagination, page_size: pageSize } },
    )
  }
}
