import { Injectable } from '@angular/core'
import { GradesRatingListResponse } from 'common/models/grades-rating-list-response'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class TopService {
  constructor(private requestService: RequestService) {}

  getTopGrades() {
    return this.requestService.post<unknown, GradesRatingListResponse>('/api/account/grades_rating', {})
  }
}
