import { createReducer, on } from '@ngrx/store'
import { deliveryIsCourierSetValue } from './delivery-is-courier.actions'

export const deliveryIsCourierFeatureKey = 'deliveryIsCourier'

export interface State {
  value: boolean
}

export const initialState: State = {
  value: false,
}

export const reducer = createReducer(
  initialState,
  on(deliveryIsCourierSetValue, (state, { data }) => ({ value: data })),
)
