import { createReducer, on } from '@ngrx/store'
import { Order } from 'common/models/order'
import { approveGroupOrder, cancelGroupOrder, changeCreateOrderStep, createGroupOrder, loadGroupOrderInfo, loadOrders, loadOrdersAdmin, resetOrdersState, updateOrder } from './orders.actions'
import { Navigation } from 'common/models/navigation'

export const ordersFeatureKey = 'orders'
export enum OrderTabs {
  standardOrder = 'standardOrder',
  groupOrder = 'groupOrder',
}

export interface State {
  items: Order[]
  navigation: Navigation
  int_id?: number // для поиска по числовому id
  loading: boolean
  
  // для групповых заказов
  groupOrderInfo: Order | null
  createOrderStep: number
  approveGroupOrder: boolean
  createGroupOrderSuccess: boolean
}

const initialState: State = {
  items: [],
  navigation: {
    page: 0,
    size: 0,
    total: 0,
  },
  loading: false,
  groupOrderInfo: null,
  createOrderStep: 1,
  approveGroupOrder: false,
  createGroupOrderSuccess: false
}

export const reducer = createReducer(
  initialState,
  on(loadOrders.start, loadOrdersAdmin.start, state => ({ ...state, loading: true })),
  on(loadOrders.error, loadOrdersAdmin.error, state => ({ ...state, loading: false })),
  on(loadOrders.success, (state, { orders, navigation, int_id }) => {
    return { ...state, items: orders || [], navigation: navigation || initialState.navigation, int_id, loading: false }
  }),
  on(loadOrdersAdmin.success, (state, { orders, navigation }) => {
    return { ...state, items: orders || [], navigation: navigation || initialState.navigation, loading: false }
  }),
  on(createGroupOrder.start, state => {
    return { ...state, loading: true, createGroupOrderSuccess: false}
  }),
  on(createGroupOrder.error, state => {
    return { ...state, loading: false, createGroupOrderSuccess: false}
  }), 
  on(createGroupOrder.success, (state, { order }) => {
    return { ...state, loading: false, createGroupOrderSuccess: true, groupOrderInfo: order, createOrderStep: 3}
  }),
  on(loadGroupOrderInfo.start, (state, { id }) => {
    return { ...state, loading: true, groupOrderInfo: null }
  }),
  on(loadGroupOrderInfo.success, (state, { order }) => {
    return { 
      ...state, 
      loading: false, 
      groupOrderInfo: order,
      approveGroupOrder: false
    }
  }),
  on(loadGroupOrderInfo.error, state => {
    return { 
      ...state, 
      loading: false, 
      groupOrderInfo: null,
      approveGroupOrder: false 
    }
  }),
  on(changeCreateOrderStep, (state, { step }) => ({
    ...state,
    createOrderStep: step
  })),
  on(approveGroupOrder, (state, { approve }) => ({
    ...state,
    approveGroupOrder: approve,
  })),
  on(cancelGroupOrder, state => ({
    ...state,
    approveGroupOrder: false,
    groupOrderInfo: null
  })),
  on(resetOrdersState, () => initialState),
  on(updateOrder, (state, { order }) => ({
    ...state,
    items: state.items.map(item => (item.id === order.id ? order : item)),
  })),
)
