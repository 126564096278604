import { Component, HostBinding, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent {
  @Input() isPageWrapper = false
  @Input() narrow = false
  @Input() small = false
  @Input() small2 = false

  @HostBinding('class.page-wrapper') get getPageWrapperClass() {
    return this.isPageWrapper
  }

  @HostBinding('class.narrow') get getNarrowClass() {
    return this.narrow
  }

  @HostBinding('class.small') get getSmallClass() {
    return this.small
  }
  @HostBinding('class.small-2') get getSmall2Class() {
    return this.small2
  }
}
