import { createReducer, on } from '@ngrx/store'
import {
  addCartItemSuccess,
  cartEndChanging,
  cartEstimateDeliverySuccess,
  cartStartChanging,
  cartSaveCitySuccess,
  cartSaveDeliveryMethodSuccess,
  deleteCartItemSuccess,
  getCart,
  getCartError,
  getCartSuccess,
  updateCartItemSuccess,
  updateManyCartItems,
  updateManyCartItemsSuccess,
  clearCart,
  updatePersonalInfo,
  addCartItem,
  updateCartItem,
  deleteCartItem,
  cartEstimateDelivery,
  cartSaveCity,
  cartSaveCountry,
  cartSaveDeliveryMethod,
  addCartItemError,
  updateCartItemError,
  deleteCartItemError,
  updateManyCartItemsError,
  cartEstimateDeliveryError,
  cartSaveCityError,
  cartSaveDeliveryMethodError,
  updateStock,
  cartSaveRegion, changeCountryTest, updateTotalCurrencyPrice, approveGroupOrder,
} from './cart.actions'
import { CartWithProducts } from '../../../../../common/models/cart-with-products'

export const cartFeatureKey = 'cart'

export interface State {
  isCartLoading: boolean
  isCartChanging: boolean
  cart?: CartWithProducts
}

export const initialState: State = {
  isCartChanging: false,
  isCartLoading: false,
}

export const reducer = createReducer(
  initialState,
  on(
    getCart,
    addCartItem,
    updateCartItem,
    deleteCartItem,
    updateManyCartItems,
    cartEstimateDelivery,
    changeCountryTest,
    cartSaveCity,
    cartSaveCountry.start,
    cartSaveRegion.start,
    cartSaveDeliveryMethod,
    clearCart.start,
    updatePersonalInfo.start,
    state => {
      return { ...state, isCartLoading: true }
    },
  ),
  on(
    getCartSuccess,
    addCartItemSuccess,
    updateCartItemSuccess,
    deleteCartItemSuccess,
    updateManyCartItemsSuccess,
    cartEstimateDeliverySuccess,
    cartSaveCitySuccess,
    cartSaveCountry.success,
    cartSaveRegion.success,
    cartSaveDeliveryMethodSuccess,
    updatePersonalInfo.success,
    updateStock.success,
    clearCart.success,
    approveGroupOrder.success,
    (state, { cart }) => {
      return {
        ...state,
        cart,
        isCartLoading: false,
      }
    },
  ),
  on(
    getCartError,
    addCartItemError,
    updateCartItemError,
    deleteCartItemError,
    updateManyCartItemsError,
    cartEstimateDeliveryError,
    cartSaveCityError,
    cartSaveCountry.error,
    cartSaveRegion.error,
    cartSaveDeliveryMethodError,
    clearCart.error,
    updatePersonalInfo.error,
    approveGroupOrder.error,
    state => ({ ...state, isCartLoading: false }),
  ),
  on(cartStartChanging, state => ({ ...state, isCartChanging: true })),
  on(cartEndChanging, state => ({ ...state, isCartChanging: false })),
  //TODO: Убрать тип any после разбора стейта, добавить актуальный тип
  on(updateTotalCurrencyPrice.success, (state, { prices }): any => ({
     ...state, prices
  }))
)
