import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { registerPerson } from './register-person.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { RegisterPersonService } from '../lib/register-person.service'

@Injectable()
export class RegisterPersonEffects {
  constructor(private actions$: Actions, private registerPersonService: RegisterPersonService) {}

  registerPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerPerson.start),
      mergeMap(({ data, who }) =>
        this.registerPersonService.registerPerson(data, who).pipe(
          map(() => registerPerson.success({ data, who })),
          catchError(error => of(registerPerson.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
