import { createReducer, on } from '@ngrx/store'
import { kazpostLoad, kazpostLoadError, kazpostLoadSuccess, kazpostRemove } from './kazpost.actions'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { KazpostOffice } from '../../../../../../../common/models/kazpost-office'

export const kazpostFeatureKey = 'kazpost'

export interface State extends EntityState<KazpostOffice> {
  isLoading: boolean
  isLoaded: boolean
}

const selectId = (v: KazpostOffice) => v.postcode as string

const sortById = (a: KazpostOffice, b: KazpostOffice) => a.postcode!.localeCompare(b.postcode!)

export const adapter = createEntityAdapter<KazpostOffice>({
  selectId,
  sortComparer: sortById,
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false,
})

export const reducer = createReducer(
  initialState,
  on(kazpostLoad, state => ({ ...state, isLoading: true })),
  on(kazpostLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
  on(kazpostLoadError, state => ({ ...state, isLoading: false })),
  on(kazpostRemove, () => initialState),
)
