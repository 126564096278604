import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TopService } from '../api/top.service'
import { loadTopSallers } from './top.actions'

@Injectable()
export class TopEffects {
  constructor(private actions$: Actions, private topService: TopService) {}

  loadMinions = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTopSallers.start),
      mergeMap(() =>
        this.topService.getTopSallers().pipe(
          map(response => loadTopSallers.success(response)),
          catchError(error => of(loadTopSallers.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
