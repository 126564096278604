import { isNullable } from './value'
import { DateTime } from 'luxon'
import { Datestamp } from '../../models/datestamp'
import { MonthNumbers } from 'luxon/src/datetime'

export const toTimeStr = (timestamp?: DateTime | number): string =>
	isNullable(timestamp)
		? '-'
		: DateTime.fromMillis(Number(timestamp)).toLocaleString(Object.assign(DateTime.TIME_24_SIMPLE))

export const fromTimeStr = (date: DateTime | number, string_: string): DateTime => {
	const [hours, minutes] = string_.split(':')
	return DateTime.fromMillis(Number(date))
		.set({
			hour: Number(hours),
			minute: minutes ? Number(minutes) : 0
		})
		.startOf('minute')
}

export const toDateStr = (timestamp?: DateTime | number): string =>
	isNullable(timestamp)
		? '-'
		: DateTime.fromMillis(Number(timestamp)).toLocaleString(Object.assign(DateTime.DATE_SHORT, { hour12: false }))

export const toDateMonthYearStr = (timestamp?: DateTime | number): string => {
	if (isNullable(timestamp)) {
		return ''
	}

	const date = DateTime.fromMillis(Number(timestamp))
	let formattedDate = date.toLocaleString({ month: 'long', year: 'numeric' })
	formattedDate = formattedDate.replace(' г.', '')

	return formattedDate
}

export const toDateTimeStr = (timestamp?: DateTime | number): string =>
	isNullable(timestamp)
		? '-'
		: DateTime.fromMillis(Number(timestamp)).toLocaleString(
				Object.assign(DateTime.DATETIME_SHORT, { hour12: false })
		  )

export const toDateLongTimeStr = (timestamp?: DateTime | number): string =>
	isNullable(timestamp)
		? '-'
		: DateTime.fromMillis(Number(timestamp)).toLocaleString(Object.assign(DateTime.DATETIME_MED, { hour12: false }))

export const startOfDay = (timestamp?: DateTime | number): DateTime =>
	DateTime.fromMillis(Number(timestamp)).startOf('day')

export const toNativeDate = (timestamp: number): Date => {
	const date = DateTime.fromMillis(timestamp)
	return new Date(date.year, date.month - 1, date.day, date.hour, date.minute, date.second, date.millisecond)
}

export const convertTimestampToDateTimeWithTimezone = (
	timestamp: number | undefined,
	timezone: string | undefined
): string => {
	if (!timestamp || !timezone) {
		return ''
	}

	const date = DateTime.fromSeconds(Number(timestamp / 1000), { zone: timezone })
	return date.toLocaleString(Object.assign(DateTime.DATETIME_MED, { hour12: false }))
}

export const toDatestamp = (timestamp: DateTime | number | undefined | null): Datestamp | undefined => {
	if (!timestamp) {
		return undefined
	}
	const date = DateTime.fromMillis(Number(timestamp), { zone: 'Europe/Moscow' })
	return date.toObject()
}
export const addBusinessDays = (date: DateTime | number, days: number): DateTime => {
	date = DateTime.fromMillis(Number(date)).plus({ day: 1 }).startOf('day')
	// skip partial day
	const day = date.weekday // 1 is monday, 7 is sunday
	const daysUntilWeekend = 6 - day
	if (daysUntilWeekend >= days) {
		return date.plus({ day: days })
	}
	date = date.plus({ day: daysUntilWeekend })
	const weekQty = Math.floor((days - daysUntilWeekend) / 5)
	date = date.plus({ week: weekQty })
	date = date.plus({ day: 2 + ((days - daysUntilWeekend) % 5) })
	if (date.month === 1 && date.day <= 3) {
		date = date.plus({ days: 3 })
	}
	return date
}

export const toStringDateTime = (str: string | undefined | null): string | undefined => {
	if (!str) {
		return undefined
	}

	let date = Date.parse(str)
	return DateTime.fromMillis(Number(date)).toLocaleString(Object.assign(DateTime.DATE_SHORT, { hour12: false }))
}

export const years: number[] = [2023, 2024]

export const months = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь'
] as const

export type Months = typeof months[number]

export function monthToString(i: MonthNumbers): Months {
	return months[i - 1]
}

export function monthJSToString(i: Date): string {
	return months[i.getMonth()].toLowerCase() + ' ' + i.getFullYear()
}
