/**
 * Ewa Product service
 * Ewa
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DeliveryMethod = 'Cdek' | 'Kazpost' | 'Yandex' | 'KgPost' | 'Europost' | 'Cargo' | 'RuPost' | 'DPD' | 'Boxberry' | 'GroupOrder' | 'FakeDelivery' | 'FivePost' | 'SelfPickup' | 'Haypost' | 'Fargo' | 'Unknown';

export const DeliveryMethod = {
    Cdek: 'Cdek' as DeliveryMethod,
    Kazpost: 'Kazpost' as DeliveryMethod,
    Yandex: 'Yandex' as DeliveryMethod,
    KgPost: 'KgPost' as DeliveryMethod,
    Europost: 'Europost' as DeliveryMethod,
    Cargo: 'Cargo' as DeliveryMethod,
    RuPost: 'RuPost' as DeliveryMethod,
    Dpd: 'DPD' as DeliveryMethod,
    Boxberry: 'Boxberry' as DeliveryMethod,
    GroupOrder: 'GroupOrder' as DeliveryMethod,
    FakeDelivery: 'FakeDelivery' as DeliveryMethod,
    FivePost: 'FivePost' as DeliveryMethod,
    SelfPickup: 'SelfPickup' as DeliveryMethod,
    Haypost: 'Haypost' as DeliveryMethod,
    Fargo: 'Fargo' as DeliveryMethod,
    Unknown: 'Unknown' as DeliveryMethod
};

