import { createFeatureSelector, createSelector } from '@ngrx/store'
import { adapter, regionFeatureKey, State } from './region.reducer'

const { selectEntities, selectAll } = adapter.getSelectors()

export const selectRegionState = createFeatureSelector<State>(regionFeatureKey)

export const selectRegionEntities = createSelector(selectRegionState, selectEntities)
export const selectRegionsAll = createSelector(selectRegionState, selectAll)

export const selectRegionIsLoading = createSelector(selectRegionState, state => state.isLoading)

export const selectRegionNotFound = createSelector(selectRegionsAll, selectRegionIsLoading, (regions, isLoading) => {
  return !isLoading && regions?.length === 0
})
