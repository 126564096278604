import { createFeatureSelector, createSelector } from '@ngrx/store'
import { State, featureKey } from './geo-search.reducer'

export const selectGeoSearchState = createFeatureSelector<State>(featureKey)

export const selectCountries = createSelector(selectGeoSearchState, s => s.countries)
export const selectCountiesLoading = createSelector(selectGeoSearchState, s => s.isLoadingCountries)
export const noCountriesFound = createSelector(selectCountries, selectCountiesLoading, (countries, loading) => {
  return !loading && countries?.length === 0
})

export const selectRegions = createSelector(selectGeoSearchState, s => s.regions)
export const selectionRegionsLoading = createSelector(selectGeoSearchState, s => s.isLoadingRegions)
export const noRegionsFound = createSelector(selectRegions, selectionRegionsLoading, (regions, loading) => {
  return !loading && regions?.length === 0
})

export const selectCities = createSelector(selectGeoSearchState, s => s.cities)
export const selectCitiesLoading = createSelector(selectGeoSearchState, s => s.isLoadingCities)
export const noCitiesFound = createSelector(selectCities, selectCitiesLoading, (cities, loading) => {
  return !loading && cities?.length === 0
})
