<h2 class="modal__title" mat-dialog-title>Уважаемый партнер!</h2>
<mat-dialog-content class="modal__content">
	<p class="modal__text">
		Уведомляем Вас об окончании периода для активации контракта в конце текущего периода. В случае отсутствия
		активации контракта на 50б и более - Ваша роль будет переведена в "Сlient".
	</p>

	<p class="modal__text">С уважением, команда EWA PRODUCT</p>
</mat-dialog-content>
<mat-dialog-actions class="modal__actions" align="end">
	<label class="modal__label" for="hideNoticeCheckbox">
		<span [class.checked]="hideNoticeCheckbox" class="modal__checkbox"></span>
		<input
			class="modal__checkbox-input"
			id="hideNoticeCheckbox"
			type="checkbox"
			(click)="onHideNoticeClick($event)"
		/>
		Больше не показывать</label
	>
	<button app-button class="modal__action-btn" (click)="close()">OK</button>
</mat-dialog-actions>
