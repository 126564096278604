import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppState } from '../../../store/state'
import { ContactPageService } from '../services/geography-page.service'
import { getContactsData } from './geography-page.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { EwaOffice } from 'common/models/ewa-office'

@Injectable()

export class ContactPageEffects {
  constructor(private actions$: Actions, private contactService: ContactPageService, private store: Store<AppState>) {}

  getContactsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getContactsData.start),
      mergeMap(() =>
        this.contactService.getContactsData().pipe(
          map((contactsData) => getContactsData.success({ contactsData })),
          catchError(error => of(getContactsData.error({ error: String(error) }))),
        ),
      )
    ),
  )
}
