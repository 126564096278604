import { Language } from '../../utils/constants/language'

export const Constants = {
	LOADING_DELAY: 1000,
	DEFAULT_LANG: Language.Russian,
	NOTIFY_SUCCESS: 3000,
	NOTIFY_ERROR: 10_000,
	MAIN_SLIDER: {
		slidesPerView: 1,
		pagination: {
			clickable: true
		},
		autoplay: {
			delay: 10_000,
			disableOnInteraction: false
		},
		loop: true
	}
}
