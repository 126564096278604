/**
 * Ewa Product service
 * Ewa
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RupostInternationalDeliveryKind = 'RupostInternationalDeliveryNone' | 'RupostInternationalDeliveryClassic' | 'RupostInternationalDeliveryEMS';

export const RupostInternationalDeliveryKind = {
    RupostInternationalDeliveryNone: 'RupostInternationalDeliveryNone' as RupostInternationalDeliveryKind,
    RupostInternationalDeliveryClassic: 'RupostInternationalDeliveryClassic' as RupostInternationalDeliveryKind,
    RupostInternationalDeliveryEms: 'RupostInternationalDeliveryEMS' as RupostInternationalDeliveryKind
};

