import { createActionGroup, props } from '@ngrx/store'
import { StocksResponse } from 'common/models/stocks-response'

export const getStocks = createActionGroup({
	source: 'Get all stocks',
	events: {
		start: props<{ countryId: string }>(),
		success: props<{ response: StocksResponse }>(),
		error: props<{ error: string }>()
	}
})

export const getStocksForGroupOrders = createActionGroup({
	source: 'Get all stocks for group orders',
	events: {
		start: props<{ countryId: string }>(),
		success: props<{ response: StocksResponse }>(),
		error: props<{ error: string }>()
	}
})

export const allErrors = [getStocks.error, getStocksForGroupOrders.error]
