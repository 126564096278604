import { createActionGroup, props } from '@ngrx/store'
import { FundsTransferListResponse } from 'common/models/funds-transfer-list-response'

export const getTransferHistoryActions = createActionGroup({
  source: 'Transfer History page get transfers',
  events: {
    start: props<{
      page: number
    }>(),
    success: props<FundsTransferListResponse>(),
    error: props<{ error: string }>(),
  },
})

export const allErrors = [getTransferHistoryActions.error]
