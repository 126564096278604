import { createReducer, on } from '@ngrx/store'
import * as eventsActions from './events.actions'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Event } from 'common/models/event'
import { Navigation } from 'common/models/navigation'

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>({})
export const { selectAll } = adapter.getSelectors()

export const featureKey = 'archiveEvents'

export interface State extends EntityState<Event> {
  loadingEvents: boolean
  navigation: Navigation | null
  currentEventItem: Event | null
}

export const initialState: State = adapter.getInitialState({
  loadingEvents: false,
  navigation: null,
  currentEventItem: null,
})

export const reducer = createReducer(
  initialState,
  on(eventsActions.getArchiveEventsList.start, state => ({...state, loadingEvents: true})),
  on(eventsActions.getArchiveEventsList.success, (state, { response }) => ({
    ...adapter.setAll(response.events ?? [], state),
    navigation: response.navigation ?? null,
    loadingEvents: false,
  })),
  on(eventsActions.getArchiveEventsList.error, state => ({ ...state, loadingEvents: false })),
)
