import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { activateLoadingForAWhile } from './loading.actions';
import { Store } from '@ngrx/store'
import { AppState } from '../state'
import { catchError, map, mergeMap, of, timer } from 'rxjs';
import { Constants } from '../../../../common/utils/constants/constants';

@Injectable()
export class LoadingEffects {
	constructor(private actions$: Actions, private store: Store<AppState>) {}

	activateLoadingForAWhile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(activateLoadingForAWhile.start),
			mergeMap(() =>
				timer(Constants.LOADING_DELAY).pipe(
					map(() => activateLoadingForAWhile.success()),
					catchError(error => of(activateLoadingForAWhile.error({ error: error }))),
				),
			),
		),
	)
}
