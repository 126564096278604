import { createAction, props } from '@ngrx/store'
import { KgPostOffice } from 'common/models/kg-post-office'
import { errorProps } from '../../../../../../../common/utils/store/types'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'

export const kgpostLoad = createAction('[KgPost] load', cityIdProps)

export const kgpostLoadSuccess = createAction('[KgPost] load success', props<{ data: KgPostOffice[] }>())

export const kgpostLoadError = createAction('[KgPost] load error', errorProps)

export const kgpostRemove = createAction('[KgPost] remove')
