/**
 * Ewa Product service
 * Ewa
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Роль пользователя
 */
export type RoleUser = 'RoleAdmin' | 'RoleClient' | 'RolePartner' | 'RoleSupport';

export const RoleUser = {
    RoleAdmin: 'RoleAdmin' as RoleUser,
    RoleClient: 'RoleClient' as RoleUser,
    RolePartner: 'RolePartner' as RoleUser,
    RoleSupport: 'RoleSupport' as RoleUser
};

