import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { ReferralService } from '../api/referral.service'
import { getUserInfoByToken, registerViaReferral } from './register-via-referral.actions'

@Injectable()
export class RegisterViaReferralEffects {
  constructor(private actions$: Actions, private referralService: ReferralService) {}

  registerPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerViaReferral.start),
      mergeMap(({ data, who }) =>
        this.referralService.registerWithReferralToken(data, who).pipe(
          map(() => registerViaReferral.success({ data, who })),
          catchError(error => of(registerViaReferral.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  getUserInfoByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserInfoByToken.start),
      mergeMap(({ token }) =>
        this.referralService.getUserInfoByToken(token).pipe(
          map(response => getUserInfoByToken.success({ response })),
          catchError(error => of(getUserInfoByToken.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
