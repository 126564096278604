import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { FundsTransfer } from 'common/models/funds-transfer'
import { FundsTransferRequest } from 'common/models/funds-transfer-request'
import { FundsTransferWithdrawalResponse } from 'common/models/funds-transfer-withdrawal-response'
import { MinionFromMyTeamResponse } from 'common/models/minion-from-my-team-response'

export const sendBonus = createActionGroup({
	source: 'Transfer Bonus form send',
	events: {
		start: props<{
			bonusAmount: number
			partnerVisibleId: number
			balanceType: FundsTransferRequest.BalanceTypeEnum
			comment: string
		}>(),
		success: props<{ transfer: FundsTransfer }>(),
		error: props<{ error: string }>()
	}
})

export const getMinionWithdrawInfo = createActionGroup({
	source: 'Get minions withdraw info',
	events: {
		start: emptyProps(),
		success: props<{ response: FundsTransferWithdrawalResponse }>(),
		error: props<{ error: string }>()
	}
})

export const getMinionById = createActionGroup({
	source: 'Transfer Bonus form get minion by id',
	events: {
		start: props<{
			visibleId: number
		}>(),
		success: props<{ response: MinionFromMyTeamResponse }>(),
		error: props<{ error: string }>()
	}
})

export const withdrawBonus = createActionGroup({
	source: 'Transfer Bonus form withdraw',
	events: {
		start: props<{
			bonusAmount: number
		}>(),
		success: props<{ transfer: FundsTransfer }>(),
		error: props<{ error: string }>()
	}
})

export const allErrors = [sendBonus.error, withdrawBonus.error]
