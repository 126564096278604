import { createReducer, on } from '@ngrx/store'
import { clearTokenInfo, getUserInfoByToken, registerViaReferral } from './register-via-referral.actions'
import { CheckRefParameterResponse } from 'common/models/check-ref-parameter-response'

export const registerViaReferralFeatureKey = 'register-via-referral'

export interface State {
  loading: boolean
  checkingToken: boolean
  checkTokenResponse: CheckRefParameterResponse | undefined
}

export const initialState: State = {
  loading: false,
  checkingToken: false,
  checkTokenResponse: undefined,
}

export const reducer = createReducer(
  initialState,
  on(registerViaReferral.start, state => ({ ...state, loading: true })),
  on(registerViaReferral.success, state => ({ ...state, loading: false })),
  on(registerViaReferral.error, state => ({ ...state, loading: false })),

  on(getUserInfoByToken.start, state => ({ ...state, checkingToken: true })),
  on(getUserInfoByToken.success, (state, { response }) => ({
    ...state,
    checkingToken: false,
    checkTokenResponse: response,
  })),
  on(getUserInfoByToken.error, state => ({ ...state, checkingToken: false })),

  on(clearTokenInfo, state => ({ ...state, checkTokenResponse: undefined })),
)
