import { Injectable } from '@angular/core'
import { CartItem } from 'common/models/cart-item'
import { RequestService } from 'common/services/request.service'
import { map, Observable, tap } from 'rxjs'
import { UpdateDeliveryInCartRequest } from '../../../../../common/models/update-delivery-in-cart-request'
import { AddCartCityRequest } from '../../../../../common/models/add-cart-city-request'
import { AddCartDeliveryMethodRequest } from '../../../../../common/models/add-cart-delivery-method-request'
import { CartWithProducts } from '../../../../../common/models/cart-with-products'
import { UpdateCountryInCartRequest } from '../../../../../common/models/update-country-in-cart-request'
import { UpdatePersonalDataInCartRequest } from '../../../../../common/models/update-personal-data-in-cart-request'
import { AddCartRegionRequest } from '../../../../../common/models/add-cart-region-request'
import { Country } from '../../../../../common/models/country'
import { OrderIntIdRequest } from 'common/models/order-int-id-request'

type Id = number
type Amount = number

@Injectable({
	providedIn: 'root'
})
export class CartService {
	constructor(private readonly requestService: RequestService) {}

	public addToCart(itemId: number): Observable<CartWithProducts> {
		return this.requestService.post<CartItem, CartWithProducts>('api/cart/update', {
			item_id: itemId,
			quantity: 1
		})
	}

	public changeCountInCard(itemId: number, quantity: number): Observable<CartWithProducts> {
		return this.requestService.post<CartItem, CartWithProducts>('api/cart/update', {
			item_id: itemId,
			quantity
		})
	}

	public approveGroupOrderRequest(req: OrderIntIdRequest) {
		return this.requestService.post<OrderIntIdRequest, CartWithProducts>('api/orders/group_order/confirm', req)
	}

	public getCart(): Observable<CartWithProducts> {
		return this.requestService.post<unknown, CartWithProducts>('api/cart/full_data', {})
	}

	public updateCartItem(item: CartItem): Observable<CartWithProducts> {
		return this.requestService.post<CartItem, CartWithProducts>('api/cart/update', item)
	}

	public deleteCartItem(itemId: number): Observable<CartWithProducts> {
		return this.requestService.post<Pick<CartItem, 'item_id'>, CartWithProducts>('api/cart/delete', {
			item_id: itemId
		})
	}

	public updateManyCartItems(idAmountMap: Map<Id, Amount>) {
		const requestItems: CartItem[] = []

		for (const [id, amount] of idAmountMap.entries()) {
			requestItems.push({ item_id: id, quantity: amount })
		}

		return this.requestService.post<{ products: CartItem[] }, CartWithProducts>('api/cart/update_multi', {
			products: requestItems
		})
	}

	public estimateDelivery(req: UpdateDeliveryInCartRequest): Observable<CartWithProducts> {
		return this.requestService.post('/api/cart/estimate_delivery', req)
	}

	public saveCity(req: AddCartCityRequest): Observable<CartWithProducts> {
		return this.requestService.post('/api/cart/save_city', req)
	}

	public updateCountry(req: UpdateCountryInCartRequest): Observable<CartWithProducts> {
		return this.requestService.post('/api/cart/update_country', req)
	}

	public saveRegion(req: AddCartRegionRequest): Observable<CartWithProducts> {
		return this.requestService.post('/api/cart/save_region', req)
	}

	public saveDeliveryMethod(req: AddCartDeliveryMethodRequest): Observable<CartWithProducts> {
		return this.requestService.post('/api/cart/save_delivery_method', req)
	}

	public clearCart() {
		return this.requestService.post<unknown, CartWithProducts>('/api/cart/clear', {})
	}

	public updatePersonalInfo(info: UpdatePersonalDataInCartRequest): Observable<CartWithProducts> {
		return this.requestService.post<UpdatePersonalDataInCartRequest, CartWithProducts>(
			'api/cart/update_personal_data',
			info
		)
	}

	public updateStock(id: number) {
		return this.requestService.post<{ stock_id: number }, CartWithProducts>('/api/cart/update_stock', {
			stock_id: id
		})
	}

	public updateCurrency(id: string) {
		return this.requestService.post<{ currency_id: string }, CartWithProducts>('/api/cart/update_currency', {
			currency_id: id
		})
	}

	// TODO: убрать any после разбора стейта, добавить норм интерфейс
	public updateCartTotalPriceCurrency(): Observable<any> {
		return this.requestService.post<unknown, Observable<any>>('/api/orders/calculation', {})
	}

	public changeCountryById(country: Country): Observable<CartWithProducts> {
		return this.requestService.post<unknown, CartWithProducts>('/api/cart/update_user_country', {
			country_id: country.id
		})
	}
}
