import { Injectable } from '@angular/core'
import { MinionFromMyTeamResponse } from 'common/models/minion-from-my-team-response'
import { RequestService } from 'common/services/request.service'
import { GetMinionMyTeamRequest } from '../../../../../../common/models/get-minion-my-team-request'

@Injectable({ providedIn: 'root' })
export class PartnerService {
  constructor(private requestService: RequestService) {}

  getMinionById(req: GetMinionMyTeamRequest) {
    return this.requestService.post<GetMinionMyTeamRequest, MinionFromMyTeamResponse>(`/api/my_team/get_minion_v2`, req)
  }
}
