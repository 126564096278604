import { Injectable } from '@angular/core'
import { catchError, map, mergeMap, of, switchMap } from 'rxjs'
import { getProduct, getProductsAndCategories, addFavorite, deleteFavorite } from './shop.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ShopService } from '../../features/shop/services/shop.service'
import { Router } from '@angular/router'

@Injectable()
export class ShopEffects {
  constructor(
    private actions$: Actions, 
    private shopService: ShopService, 
    private router: Router
  ) {}

  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProductsAndCategories.start),
      mergeMap(() =>
        this.shopService.getProductsAndCategories().pipe(
          map(([products, categories]) => getProductsAndCategories.success({ products, categories })),
          catchError(error => of(getProductsAndCategories.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  loadSingleProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProduct.start),
      mergeMap(({ slug, stock_id }) =>
        this.shopService.getSingleProduct(slug, stock_id).pipe(
          map(product => getProduct.success({ product })),
          catchError(error => {
            this.router.navigateByUrl('/shop');
            return of(getProduct.error({ error: String(error) }));
          }),
        ),
      ),
    ),
  )

  addFavorite$ = createEffect(() => this.actions$.pipe(
    ofType(addFavorite.start),
    switchMap(({ productId }: { productId: number }) => 
      this.shopService.addFavoriteProduct(productId).pipe(
        map(() => addFavorite.success({ productId, success: true })),
        catchError(error => of(addFavorite.error({ error })))
      )
    )
  ));

  deleteFavorite$ = createEffect(() => this.actions$.pipe(
    ofType(deleteFavorite.start),
    switchMap(({ productId }: { productId: number }) => 
      this.shopService.deleteFavoriteProduct(productId).pipe(
        map(() => deleteFavorite.success({ productId, success: false })),
        catchError(error => of(deleteFavorite.error({ error })))
      )
    )
  ));
}
