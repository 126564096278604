import { Pipe, PipeTransform } from '@angular/core'
import { Cart } from '../../../../../common/models/cart'
import { DeliveryMethod } from '../../../../../common/models/delivery-method'
import { CartWithProducts } from '../../../../../common/models/cart-with-products'
import { AppDeliveryMethodData } from '../lib/delivery-method-data'

export const advanceCostsDeliveryMethods = (
  cart: CartWithProducts | Cart | undefined | null,
): AppDeliveryMethodData[] => {
  if (!cart?.advance_costs) {
    return []
  }
  const methods: Record<DeliveryMethod, AppDeliveryMethodData> = Object.create(null)
  for (const advCost of cart.advance_costs) {
    if (advCost && advCost.method) {
      const method: AppDeliveryMethodData = methods[advCost.method] || {}
      method.method = advCost.method
      method.courierAvailable = method.courierAvailable || advCost.is_courier
      method.officeAvailable = method.officeAvailable || !advCost.is_courier
      method.isInternational = method.isInternational || advCost.is_international
      methods[advCost.method] = method
    }
  }
  return Object.values(methods)
}

/**
 * Бек сделал так, что в итоге методы доставки нужно высчитывать по
 * advance_costs. Но селектор методов доставки принимает массив DeliveryMethodData,
 * поэтому нужна пайпа для преобразования одного в другое
 */
@Pipe({
  name: 'appAdvanceCostsDeliveryMethods',
  standalone: true,
})
export class AdvanceCostsDeliveryMethodsPipe implements PipeTransform {
  transform(cart: CartWithProducts | Cart | undefined | null): AppDeliveryMethodData[] {
    return advanceCostsDeliveryMethods(cart)
  }
}
