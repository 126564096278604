import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { EventsService } from '../../api/events.service'
import { getActiveEventsList } from './events.actions'

@Injectable()
export class ActiveEventsEffects {
  getActiveEventsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getActiveEventsList.start),
      mergeMap(() =>
        this.eventsService.getActiveEventsList().pipe(
          map(response => getActiveEventsList.success({ response })),
          catchError(error => of(getActiveEventsList.error({ error }))),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private eventsService: EventsService) {}
}
