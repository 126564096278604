import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of, tap } from 'rxjs'
import { CicService } from './cic.service'
import {
  addAccount,
  authAccount,
  getAvailableParents,
  loadMinionDescendants,
  loadMinions,
} from './cic.actions'
import { Store } from '@ngrx/store'
import { AppState } from 'client/src/store/state'
import { AuthResponse } from 'common/models/auth-response'
import { loginAgain } from 'common/store/jwt/jwt.actions'
import { Credentials, jwtToCredentials } from 'common/utils/user/auth'
import { getCart } from 'client/src/store/checkout/cart/cart.actions'
import { JwtService } from 'ngx-customapp-jwt'
import { UserInfo } from 'common/utils/user/user'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { AvailableCicParentsResponse } from 'common/models/available-cic-parents-response'

@Injectable()
export class CicEffects {
  constructor(
    private actions$: Actions,
    private cicService: CicService,
    private jwtService: JwtService<Credentials, AuthResponse, UserInfo>,
    private router: Router,
    private dialog: MatDialog
  ) {}

  loadMinions = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMinions.start),
      mergeMap(({ page }) =>
        this.cicService.getMyTeam(page).pipe(
          map(response => loadMinions.success({ response })),
          catchError(error => of(loadMinions.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  loadMinionDescendants = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMinionDescendants.start),
      mergeMap(({ minionId, date }) =>
        this.cicService.getMinionDescendants(minionId, date).pipe(
          map(response => loadMinionDescendants.success({ ...response, minionId })),
          catchError(error => of(loadMinions.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  getAvailableParents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAvailableParents.start),
      mergeMap(() =>
        this.cicService.getAvailableParents().pipe(
          map((response: AvailableCicParentsResponse) => getAvailableParents.success({ response })),
          catchError(error => of(getAvailableParents.error({ error: String(error) })))
        ),
      ),
    )
  )

  authAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authAccount.start),
      mergeMap(({ req }) =>
        this.cicService.authAccount(req).pipe(
          tap((response: AuthResponse) => {
            this.jwtService.setJwt({
              accessToken: {
                token: response.access_token?.token || '',
                expiresAt: response.access_token?.expires_at || 0,
              },
              refreshToken: {
                token: response.refresh_token?.token || '',
                expiresAt: response.refresh_token?.expires_at || 0,
              },
            });
          }),
          mergeMap(response => {
            if (response.access_token?.token != undefined) {
              return [
                authAccount.success({ response }),
                loginAgain({ 
                  credentials: jwtToCredentials({
                    'token': response.access_token.token, 
                    'expiresAt': response.access_token.expires_at
                  }) 
                })
              ];
            } else {
              return [
                authAccount.success({ response }),
                getCart()
              ];
            }
          }),
          catchError(error => of(authAccount.error({ error })))
        )
      )
    )
  )

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authAccount.success),
      tap(() => {
        this.router.navigateByUrl('/')
        this.dialog.closeAll()
      })
    ),
    { dispatch: false }
  );
  
  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAccount.start),
      mergeMap(({ req }) =>
        this.cicService.addAccount(req).pipe(
          map(response => addAccount.success({ response })),
          catchError(error => of(addAccount.error({ error }))),
        )
      ) 
    )
  )
}
