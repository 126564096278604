import { createAction, props } from '@ngrx/store'
import { EuropostOffice } from '../../../../../../../common/models/europost-office'
import { errorProps } from '../../../../../../../common/utils/store/types'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'

export const europostLoad = createAction('[Europost] load', cityIdProps)

export const europostLoadSuccess = createAction('[Europost] load sccess', props<{ data: EuropostOffice[] }>())

export const europostLoadError = createAction('[Europost] load error', errorProps)

export const europostRemove = createAction('[Europost] remove')
