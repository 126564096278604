import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { Country } from '../../../../../../common/models/country'
import { errorProps } from '../../../../../../common/utils/store/types'

export const countriesLoad = createActionGroup({
  source: '[Delivery Countries] Load',
  events: {
    start: props<{ isDelivery: boolean }>(),
    success: props<{ data: Country[] }>(),
    error: errorProps,
  },
})

export const countriesClear = createAction('[Delivery Countries] Clear')

export const allErrors = [countriesLoad.error]
