import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { countriesLoad } from './countries.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { CountriesService } from './countries.service'

@Injectable()
export class CountriesEffects {
  constructor(private actions$: Actions, private deliveryCountriesService: CountriesService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countriesLoad.start),
      mergeMap((params) =>
        this.deliveryCountriesService.load(params.isDelivery).pipe(
          map(countries => countriesLoad.success({ data: countries })),
          catchError(error => of(countriesLoad.error({ error: error }))),
        ),
      ),
    ),
  )
}
