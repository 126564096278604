import { Injectable } from '@angular/core'
import { NoFreshJwtListener } from 'ngx-customapp-jwt'
import { Router } from '@angular/router'

const authUrl = '/'

@Injectable({
  providedIn: 'root',
})
export class NoFreshJwtListenerService implements NoFreshJwtListener {
  constructor(private router: Router) {}

  noFreshJwt(message: string) {
    console.log(message)
    void this.router.navigate([authUrl])
  }
}
