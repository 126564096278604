import { createActionGroup, emptyProps, props } from '@ngrx/store'

export const becomePartner = createActionGroup({
  source: 'Become partner send request',
  events: {
    start: emptyProps(),
    success: emptyProps(),
    error: props<{ error: string }>(),
  },
})

export const allErrors = [becomePartner.error]
