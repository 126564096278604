import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { getStocks, getStocksForGroupOrders } from './stock.actions'
import { StockSelectorService } from '../api/stock-selector.service'

@Injectable()
export class StockEffects {
  constructor(private actions$: Actions, private stocksSelectorService: StockSelectorService) {}

  getStocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStocks.start),
      mergeMap(({ countryId }) =>
        this.stocksSelectorService.getStocks(countryId).pipe(
          map(response => getStocks.success({ response })),
          catchError(error => of(getStocks.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  getStocksForGroupOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStocksForGroupOrders.start),
      mergeMap(({ countryId }) =>
        this.stocksSelectorService.getStocksForGroupOrders(countryId).pipe(
          map(response => getStocksForGroupOrders.success({ response })),
          catchError(error => of(getStocksForGroupOrders.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
