import { Injectable } from '@angular/core'
import { AccrualsPersonalVolumesListResponse } from 'common/models/accruals-personal-volumes-list-response'
import { RequestService } from 'common/services/request.service'

export const pageSize = 100

@Injectable({ providedIn: 'root' })
export class PersonalVolumeService {
  constructor(private requestService: RequestService) {}

  getPersonalVolume(pagination: { page: number }) {
    return this.requestService.post<unknown, AccrualsPersonalVolumesListResponse>(
      'api/account/history/personal_volumes',
      {},
      { params: { ...pagination, page_size: pageSize } },
    )
  }
}
