import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { ConfigService } from '../services/config.service'
import { loadConfig, loadCurrencies, loadCountries } from './config.actions'

@Injectable()
export class ConfigEffects {
  constructor(private actions$: Actions, private configService: ConfigService) {}

  loadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConfig.start),
      mergeMap(() =>
        this.configService.loadConfig().pipe(
          map(config => loadConfig.success({ config })),
          catchError(error => of(loadConfig.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  loadCountries$ = createEffect(() => 
    this.actions$.pipe(
      ofType(loadCountries.start),
      mergeMap(() =>
        this.configService.loadCountries().pipe(
          map(countries => loadCountries.success({ countries })),
          catchError(error => of(loadCountries.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  loadCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrencies.start),
      mergeMap(() =>
        this.configService.loadCurrencies().pipe(
          map(currencies => loadCurrencies.success({ currencies })),
          catchError(error => of(loadCurrencies.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
