import { createReducer, on } from '@ngrx/store'
import { applyToken, noRefToken, readonlyToken } from './apply-token.actions'
import { REFERRER_LOCALSTORAGE_KEY } from './apply-token.effects'

export const featureKey = 'apply-token'

export interface State {
	token: string | null
	noRefToken: boolean
	readonlyToken: boolean
}

export const initialState: State = {
	token: localStorage.getItem(REFERRER_LOCALSTORAGE_KEY) || null,
	noRefToken: false,
	readonlyToken: !!localStorage.getItem(REFERRER_LOCALSTORAGE_KEY)
}

export const reducer = createReducer(
	initialState,
	on(applyToken, (state, { token }) => ({ ...state, token, noRefToken: false })),
	on(noRefToken, (state, {}) => ({ ...state, token: '', noRefToken: true })),
	on(readonlyToken, (state, { s }) => ({ ...state, readonlyToken: s }))
)
