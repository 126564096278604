/**
 * Ewa Product service
 * Ewa
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FundsTransferType = 'FundsTransferTypeWithdrawal' | 'FundsTransferTypeTransfer' | 'FundsTransferTypeAccrual';

export const FundsTransferType = {
    FundsTransferTypeWithdrawal: 'FundsTransferTypeWithdrawal' as FundsTransferType,
    FundsTransferTypeTransfer: 'FundsTransferTypeTransfer' as FundsTransferType,
    FundsTransferTypeAccrual: 'FundsTransferTypeAccrual' as FundsTransferType
};

