import { Injectable } from '@angular/core'
import { Order, OrderIdRequest, PayOrderRequest } from 'common/models/models'
import { RequestService } from 'common/services/request.service'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class CheckoutService {
	constructor(private readonly requestService: RequestService) {}

	public payOrder(req: PayOrderRequest): Observable<Order> {
		if (req.payment_method === 'TestPay') {
			return this.requestService.post('/api/test/order/pay', req)
		}

		return this.requestService.post('/api/orders/pay', req)
	}

	public checkPayment(orderId: string) {
		return this.requestService.post<OrderIdRequest, Order>('api/orders/check_payment', {
			order_id: orderId
		})
	}
}
