import { createActionGroup, props } from '@ngrx/store'
import { PurchaseSettlementResponse } from 'common/models/purchase-settlement-response'
import { PartialPayment } from '../../../../../../common/models/partial-payment'

export const loadBonusInfo = createActionGroup({
  source: 'Checkout outlet load bonus info after payment',
  events: {
    start: props<{ req: PartialPayment }>(),
    success: props<{ response: PurchaseSettlementResponse }>(),
    error: props<{ error: string }>(),
  },
})

export const allErrors = [loadBonusInfo.error]
