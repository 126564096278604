import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { NewsService } from '../api/news.service'
import { getNewsList, getNewsItem } from './news.actions'

@Injectable()
export class NewsEffects {
  getNewsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNewsList.start),
      mergeMap(({ page, page_size }) =>
        this.newsService.getNewsList(page, page_size).pipe(
          map(response => getNewsList.success({ response })),
          catchError(error => of(getNewsList.error({ error }))),
        ),
      ),
    ),
  )

  getNewsItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNewsItem.start),
      mergeMap(({ id }) =>
        this.newsService.getNewsItem(id).pipe(
          map(response => getNewsItem.success({ response })),
          catchError(error => of(getNewsItem.error({ error }))),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private newsService: NewsService) {}
}
