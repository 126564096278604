import { Injectable } from '@angular/core'
import { GetTicketsRequestPagination } from 'common/models/get-tickets-request-pagination'
import { TicketsListResponse } from 'common/models/tickets-list-response'
import { RequestService } from 'common/services/request.service'
import { Observable } from 'rxjs'

export const pageSize = 100

@Injectable({ providedIn: 'root' })
export class TicketsTableService {
  constructor(private requestService: RequestService) {}

  getTickets(pagination: { page: number }): Observable<TicketsListResponse> {
    return this.requestService.post<{ pagination: GetTicketsRequestPagination }, TicketsListResponse>('api/tickets', {
      pagination: { ...pagination, page_size: pageSize },
    })
  }
}
