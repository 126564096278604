import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { RupostService } from './rupost.service'
import { rupostLoad, rupostLoadError, rupostLoadSuccess } from './rupost.actions'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class RupostEffects {
  constructor(private actions$: Actions, private rupostService: RupostService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rupostLoad),
      mergeMap(({ city_id }) =>
        this.rupostService.load(city_id).pipe(
          map(data => rupostLoadSuccess({ data })),
          catchError(error => of(rupostLoadError({ error }))),
        ),
      ),
    ),
  )
}
