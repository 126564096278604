import { Injectable } from '@angular/core'
import { ContextError, ErrorsReporter } from 'ngx-customapp-errors'
import { Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ErrorsReporterService implements ErrorsReporter {
  report(error: ContextError): Observable<void> {
    console.error('report error', error)
    return of(void 0)
  }
}
