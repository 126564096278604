<input
  #internalInput
  type="text"
  matInput
  [formControl]="control"
  [matAutocomplete]="auto"
  (focusin)="onFocusIn()"
  (blur)="handleBlur()"
  [placeholder]="placeholder"
  [required]="required"
/>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="handleSelect($event)">
  <mat-option *ngFor="let country of options$ | async" [value]="country">
    {{ country.name }}
  </mat-option>
</mat-autocomplete>
