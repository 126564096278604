export const handleInt = (v: string | number | undefined | null) => {
  if (v === undefined || v === null) {
    return undefined
  }
  const num = Number.parseInt(v + '')
  return Number.isNaN(num) ? undefined : num
}

export const handleFloat = (v: string | number | undefined | null) => {
  if (v === undefined || v === null) {
    return undefined
  }
  const num = Number.parseFloat(v + '')
  return Number.isNaN(num) ? undefined : num
}

export const preparePrice = (v: number | undefined | null) => (v !== null && v !== undefined ? v / 100 + '' : undefined)

export const handlePrice = (v: string | number | undefined | null): number | undefined => {
  const coins = handleFloat(v)
  return coins === undefined ? undefined : Math.round(coins * 100)
}

/** Converts price to UI readable numeric price */
export const toUIPrice = (value: number): number => {
  return value / 100
}
