import { createAction, props } from '@ngrx/store'
import { FargoOffice } from 'common/models/fargo-office'
import { errorProps } from '../../../../../../../common/utils/store/types'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'

export const fargoLoad = createAction('[Fargo] load', cityIdProps)

export const fargoLoadSuccess = createAction('[Fargo] load success', props<{ data: FargoOffice[] }>())

export const fargoLoadError = createAction('[Fargo] load error', errorProps)

export const fargoRemove = createAction('[Fargo] remove')
