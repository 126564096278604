import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { KgPostOffice } from 'common/models/kg-post-office'
import { kgpostLoad, kgpostLoadError, kgpostLoadSuccess, kgpostRemove } from './kgpost.actions'

export const kgpostFeatureKey = 'kgpost'

export interface State extends EntityState<KgPostOffice> {
	isLoading: boolean
	isLoaded: boolean
}

const selectId = (v: KgPostOffice) => v.postcode as string
const sortById = (a: KgPostOffice, b: KgPostOffice) => a.postcode!.localeCompare(b.postcode!)

export const adapter = createEntityAdapter<KgPostOffice>({
	selectId,
	sortComparer: sortById
})

export const initialState: State = adapter.getInitialState({
	isLoading: false,
	isLoaded: false
})

export const reducer = createReducer(
	initialState,
	on(kgpostLoad, state => ({ ...state, isLoading: true })),
	on(kgpostLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
	on(kgpostLoadError, state => ({ ...state, isLoading: false })),
	on(kgpostRemove, () => initialState)
)
