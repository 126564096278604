import { Injectable } from '@angular/core'
import { CheckRefParameterResponse } from 'common/models/check-ref-parameter-response'
import { RegRefRequest } from 'common/models/reg-ref-request'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class ReferralService {
  constructor(private requestService: RequestService) {}

  registerWithReferralToken(data: RegRefRequest, who: 'client' | 'partner') {
    return this.requestService.post<RegRefRequest, void>(`api/register/ref/${who}`, data)
  }

  getUserInfoByToken(token: string) {
    return this.requestService.post<unknown, CheckRefParameterResponse>(
      'api/check_ref_parameter',
      {},
      {
        params: { ref_parameter: token },
      },
    )
  }
}
