import { UserDataFromForm } from '../../../../../common/models/user-data-from-form'
import { Country } from '../../../../../common/models/country'
import { Region } from '../../../../../common/models/region'
import { City } from '../../../../../common/models/city'
import { DateTime } from 'luxon'
import { handlePhone } from '../../../../../common/utils/form/strings'
import { SexType } from 'common/models/sex-type'

export type RegistrationForm = Pick<UserDataFromForm, 'name' | 'surname' | 'patronymic' | 'sex' | 'phone' | 'email'> & {
  login?: string
  country?: Country
  region?: Region
  sex?: SexType
  regionName?: string // для стран не из ЕАЭС
  city?: City
  cityName?: string // для стран не из ЕАЭС
  birthday?: DateTime
}

export const userDataFromRegistrationForm = (form: RegistrationForm): UserDataFromForm => ({
  name: form.name,
  surname: form.surname,
  patronymic: form.patronymic,
  sex: form.sex,
  phone: handlePhone(form.phone),
  email: form.email,
  country_id: form.country?.id,
  region_id: form.region?.id,
  region: form.region?.id ? undefined : form.regionName,
  city_id: form.city?.id,
  city: form.city?.id ? undefined : form.cityName,
  birthday: form.birthday?.toObject(),
})
