import { Item } from '../../../../../common/models/item'
import { isItemAvailableForPreorder } from '../../../features/shop/pipes/is-item-available-for-preorder.pipe'
import { CartItemAndProduct } from '../../../../../common/models/cart-item-and-product'

export interface PreorderData {
  preorder: number | undefined
  items: Item[]
  itemNames: string[]
}

export const preorderData = (cartItems: CartItemAndProduct[], stockId: number): PreorderData => {
  const items = (cartItems
    ?.map(item => item.item)
    // приведение типов необходимо, чтобы не было (Item | undefined)[]. По факту быть и не может
    .filter(item => item && isItemAvailableForPreorder(item, stockId)) || []) as Item[]
  const itemNames = items.map(item => item.name)
  const preorder = Math.max(...items.map(item => isItemAvailableForPreorder(item, stockId) ?? 0), 0)
  return { preorder: preorder || undefined, items, itemNames }
}
