import { createReducer, on } from '@ngrx/store'
import { getPersonalAccruals } from './personal-volume.actions'
import { AccrualsPersonalVolumesListResponse } from 'common/models/accruals-personal-volumes-list-response'

export const featureKey = 'personal volume'

export interface State {
  loading: boolean
  response: AccrualsPersonalVolumesListResponse | null
}

export const initialState: State = {
  loading: false,
  response: null,
}

export const reducer = createReducer(
  initialState,
  on(getPersonalAccruals.start, state => {
    return { ...state, loading: true }
  }),
  on(getPersonalAccruals.success, (state, response) => {
    return { ...state, response, loading: false }
  }),
  on(getPersonalAccruals.error, state => {
    return { ...state, loading: false }
  }),
)
