import { createReducer, on } from '@ngrx/store'
import { activateLoadingForAWhile, setLoadingAction } from './loading.actions';

export const loadingFeatureKey = 'loading'

export interface LoadingState {
	isLoading: boolean
}

export const initialState: LoadingState = {
	isLoading: true
}

export const reducer = createReducer(
	initialState,

	on(setLoadingAction, (state, { isLoading }) => {
		return { ...state, isLoading }
	}),

	on(activateLoadingForAWhile.start, state => {
		return { ...state, isLoading: true }
	}),

	on(activateLoadingForAWhile.success, state => {
		return { ...state, isLoading: false }
	})
)
