import { ActivatedRoute } from '@angular/router'
import { map } from 'rxjs'
import { checkNan } from '../core/number'
import { DateTime } from 'luxon'

export enum QueryParams {
  selectedStep = 'selectedStep',
  visibleId = 'visibleId',
  date = 'date',
  accessToken = 'accessToken',
  accessTokenExpiresAt = 'accessTokenExpiresAt',
  refreshToken = 'refreshToken',
  refreshTokenExpiresAt = 'refreshTokenExpiresAt',
}

export const routerQueryParam = (param: QueryParams, value: number | string) => ({
  queryParams: { [param]: value + '' },
})

export const navigateQuerySelectedStep = (step: number | string) => routerQueryParam(QueryParams.selectedStep, step)

export const routeQuerySelectedStep = (route: ActivatedRoute, min: number, max: number) =>
  route.queryParams.pipe(
    // шаг степпера должен всегда существовать и быть в диапазоне [min, max]
    map(params => Math.max(Math.min(checkNan(params[QueryParams.selectedStep]) ?? 0, max), min)),
  )

export const routeQueryNum = (route: ActivatedRoute, paramName: string) =>
  route.queryParams.pipe(map(params => checkNan(params[paramName])))

export const routeQueryIsoStr = (route: ActivatedRoute, paramName: string) =>
  route.queryParams.pipe(
    map(params => {
      const dateIsoStr = params[paramName]
      return dateIsoStr ? DateTime.fromISO(dateIsoStr) : undefined
    }),
  )
