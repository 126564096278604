import { Pipe, PipeTransform } from '@angular/core'

export const minionName = (
  minionLike: { name?: string; surname?: string; patronymic?: string } | undefined | null,
): string => {
  return [minionLike?.surname, minionLike?.name, minionLike?.patronymic].filter(Boolean).join(' ')
}

@Pipe({
  name: 'appMinionName',
  standalone: true,
})
export class MinionNamePipe implements PipeTransform {
  transform(minionLike: { name?: string; surname?: string; patronymic?: string } | undefined | null): string {
    return minionName(minionLike)
  }
}
