import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TotalIncomeService } from '../api/total-income.service'
import { loadAccountBonusInfo } from './total-income.actions'

@Injectable()
export class TotalIncomeEffects {
  constructor(private actions$: Actions, private totalIncomeService: TotalIncomeService) {}

  loadAccountBonusInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAccountBonusInfo.start),
      mergeMap(({ date }) =>
        this.totalIncomeService.getHistoryInfo(date).pipe(
          map(response => loadAccountBonusInfo.success({ response })),
          catchError(error => of(loadAccountBonusInfo.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
