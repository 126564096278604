import { createReducer, on } from '@ngrx/store'
import { loginAgainErrored } from 'common/store/jwt/jwt.actions'
import { becomePartner } from './become-partner.actions'

export const featureKey = 'become-partner'

export interface State {
  loading: boolean
}

export const initialState: State = {
  loading: false,
}

export const reducer = createReducer(
  initialState,
  on(becomePartner.start, () => ({ loading: true })),
  on(becomePartner.error, () => ({ loading: false })),
  on(loginAgainErrored, () => ({ loading: false })),
)
