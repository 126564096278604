<input
  #internalInput
  type="text"
  matInput
  [formControl]="cityControl"
  [matAutocomplete]="autocomplete"
  (focusin)="onFocusIn()"
  (blur)="handleBlur()"
  [placeholder]="placeholder"
  [disabled]="isDisabled"
  [readonly]="isDisabled"
/>
<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayFn" (optionSelected)="handleSelect($event)">
  <mat-option *ngIf="notFound$ | async" disabled> Город не найден </mat-option>
  <mat-option *ngFor="let option of cities$ | async" [value]="option">
    {{ option.name }}
  </mat-option>
</mat-autocomplete>
