import { Injectable } from '@angular/core'
import { RequestService } from '../../../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { Country } from '../../../../../../common/models/country'
import { disableJwtInterception } from 'ngx-customapp-jwt'
import { HttpContext } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(private request: RequestService) {}

  load(isDelivery: boolean): Observable<Country[]> {
    let from = 'delivery';
    if (!isDelivery) {
      from = 'registration';
    } 

    return this.request.post('/api/delivery/countries?from=' + from, undefined, {
      context: new HttpContext().set(disableJwtInterception, true),
    })
  }
}
