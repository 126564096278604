import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { MyTeamResponseMinionsInner } from 'common/models/my-team-response-minions-inner'
import { Navigation } from 'common/models/navigation'
import { Filters } from '../api/team.service'
import { MyTeamResponse } from 'common/models/my-team-response'
import { Datestamp } from '../../../../../../common/models/datestamp'

export const loadMinions = createActionGroup({
	source: 'Team page minions',
	events: {
		start: props<{ page: number; filters?: Filters }>(),
		success: props<{ response: MyTeamResponse; date?: Datestamp }>(),
		error: props<{ error: string }>()
	}
})

export const loadMinionDescendants = createActionGroup({
	source: 'Team page minions descdendants',
	events: {
		start: props<{ minionId: number; date?: Datestamp }>(),
		success: props<{ navigation: Navigation; minions: MyTeamResponseMinionsInner[]; minionId: number }>(),
		error: props<{ error: string }>()
	}
})

export const removeMinionDescendants = createAction(
	'Team page remove minion descendants',
	props<{ minionId: number }>()
)

export const setPage = createAction('[Team page] set page', props<{ newPage: number }>())

export const applyFiltersAndResetPage = createAction('[Team page] apply filters', props<{ filters: Filters }>())

export const resetFiltersAndPage = createAction('[Team page] reset filters', emptyProps)

export const setDirtyStatus = createAction('[Team page] set dirty status', props<{ newStatus: boolean }>())

export type UserSelection =
	| 'allUsers'
	| 'onlyPartners'
	| 'onlyActivePartners'
	| 'onlyCic'
	| 'onlyNewUsers'
	| 'onlyTicketPurchased'
	| 'onlyFirstActivation'
	| 'onlyPartnersDowngrade'
	| 'onlyClients'
export const changeUsersSelection = createAction(
	'[Team page] change user selection',
	props<{ selection: UserSelection }>()
)

export const allErrors = [loadMinionDescendants.error, loadMinions.error]
