import { createAction, props } from '@ngrx/store'
import { KazpostOffice } from '../../../../../../../common/models/kazpost-office'
import { errorProps } from '../../../../../../../common/utils/store/types'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'

export const kazpostLoad = createAction('[Kazpost] load', cityIdProps)

export const kazpostLoadSuccess = createAction('[Kazpost] load sccess', props<{ data: KazpostOffice[] }>())

export const kazpostLoadError = createAction('[Kazpost] load error', errorProps)

export const kazpostRemove = createAction('[Kazpost] remove')
