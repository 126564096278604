import { AuthResponse } from 'common/models/auth-response'
import { jwtActions } from 'ngx-customapp-jwt'
import { Credentials } from '../../utils/user/auth'
import { UserInfo } from '../../utils/user/user'

export const {
  login,
  loginSucceed,
  loginErrored,

  logout,
  logoutSucceed,
  logoutErrored,

  loginAgain,
  loginAgainSucceed,
  loginAgainErrored,
} = jwtActions<Credentials, AuthResponse, UserInfo>()
