import { createReducer, on } from '@ngrx/store'
import { GradesRatingListResponse } from 'common/models/grades-rating-list-response'
import { loadTopGrades } from './top.actions'

export const featureKey = 'top-grades'

export interface State {
  response: GradesRatingListResponse | null
  loading: boolean
}

export const initialState: State = {
  response: null,
  loading: false,
}

export const reducer = createReducer(
  initialState,
  on(loadTopGrades.start, state => ({ ...state, loading: true })),
  on(loadTopGrades.success, state => ({ ...state, loading: false })),
  on(loadTopGrades.error, state => ({ ...state, loading: false })),

  on(loadTopGrades.success, (state, response) => {
    return {
      ...state,
      response,
    }
  }),
)
