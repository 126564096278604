import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { GroupVolumeService } from '../api/group-volume.service'
import { getGroupAccruals } from './group-volume.actions'

@Injectable()
export class GroupVolumeEffects {
  constructor(private actions$: Actions, private groupVolumeService: GroupVolumeService) {}

  loadGroupVolumeAccruals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGroupAccruals.start),
      mergeMap(({ dateFilters, pagination }) =>
        this.groupVolumeService.getGroupVolume(pagination, dateFilters).pipe(
          map(response => getGroupAccruals.success(response)),
          catchError(error => of(getGroupAccruals.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
