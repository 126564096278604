import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { FargoOffice } from 'common/models/fargo-office'
import { fargoLoad, fargoLoadError, fargoLoadSuccess, fargoRemove } from './fargo.actions'

export const fargoFeatureKey = 'fargo'

export interface State extends EntityState<FargoOffice> {
	isLoading: boolean
	isLoaded: boolean
}

const selectId = (v: FargoOffice) => v.code?.toString() as string
const sortById = (a: FargoOffice, b: FargoOffice) => a.code!.toString().localeCompare(b.code?.toString()!)

export const adapter = createEntityAdapter<FargoOffice>({
	selectId,
	sortComparer: sortById
})

export const initialState: State = adapter.getInitialState({
	isLoading: false,
	isLoaded: false
})

export const reducer = createReducer(
	initialState,
	on(fargoLoad, state => ({ ...state, isLoading: true })),
	on(fargoLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
	on(fargoLoadError, state => ({ ...state, isLoading: false })),
	on(fargoRemove, () => initialState)
)
