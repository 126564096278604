import { createReducer, on } from '@ngrx/store'
import { getContactsData } from './geography-page.actions'
import { EwaOffice } from 'common/models/ewa-office'

export const contactsFeatureKey = 'contacts'

export interface State {
  contactsData: EwaOffice[],
  isContactPageLoading: boolean,
}

export const initialState: State = {
  contactsData: [],
  isContactPageLoading: false,
}

export const reducer = createReducer(
  initialState,
  on(getContactsData.start,
    state => {
      return { ...state, isContactPageLoading: true }
    }),
  on(getContactsData.success,
    (state, { contactsData }) => {
      return {
        ...state,
        contactsData,
        isContactPageLoading: false,
      }
    }),
  on(getContactsData.error,
    state => {
      return { ...state, isContactPageLoading: false }
    }
  ),
)
