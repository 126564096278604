import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs'
import { TeamService } from '../api/team.service'
import {
  applyFiltersAndResetPage,
  loadMinionDescendants,
  loadMinions,
  resetFiltersAndPage,
  setPage,
} from './team.actions'
import { Store } from '@ngrx/store'
import { AppState } from 'client/src/store/state'
import { selectSearchInfo } from './team.selectors'

@Injectable()
export class TeamEffects {
  constructor(private actions$: Actions, private teamService: TeamService, private store: Store<AppState>) {}

  loadMinions = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMinions.start),
      mergeMap(({ page, filters }) =>
        this.teamService.getMyTeam(page, filters).pipe(
          map(response => loadMinions.success({ response, date: filters?.date })),
          catchError(error => of(loadMinions.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  loadMinionDescendants = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMinionDescendants.start),
      mergeMap(({ minionId, date }) =>
        this.teamService.getMinionDescendants(minionId, date).pipe(
          map(response => loadMinionDescendants.success({ ...response, minionId })),
          catchError(error => of(loadMinions.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  handleSearchInfoChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPage, applyFiltersAndResetPage, resetFiltersAndPage),
      withLatestFrom(this.store.select(selectSearchInfo)),
      mergeMap(([_, info]) => {
        return of(loadMinions.start({ page: info.page, filters: info.filters ?? undefined }))
      }),
    ),
  )
}
