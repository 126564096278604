import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs'
import { applyToken } from './apply-token.actions'

export const REFERRER_LOCALSTORAGE_KEY = 'referrer_token'

@Injectable()
export class ApplyTokenEffects {
	constructor(private actions$: Actions) {}

	applyToken$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(applyToken),
				map(({ token }) => {
					if (token) {
						localStorage.setItem(REFERRER_LOCALSTORAGE_KEY, token)
					} else {
						localStorage.removeItem(REFERRER_LOCALSTORAGE_KEY)
					}
				})
			),
		{ dispatch: false }
	)
}
