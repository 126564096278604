import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { MyTeamResponseMinionsInner } from 'common/models/my-team-response-minions-inner'
import { Navigation } from 'common/models/navigation'
import { MyTeamResponse } from 'common/models/my-team-response'
import { Datestamp } from '../../../../../../common/models/datestamp'
import { UserIdRequest } from 'common/models/user-id-request'
import { AuthResponse } from 'common/models/auth-response'
import { SuccessResponse } from 'common/models/success-response'
import { CicFormRequest } from 'common/models/cic-form-request'
import { AvailableCicParentsResponse } from 'common/models/available-cic-parents-response'

export const loadMinions = createActionGroup({
  source: 'Cic page minions',
  events: {
    start: props<{ page: number; }>(),
    success: props<{ response: MyTeamResponse; date?: Datestamp }>(),
    error: props<{ error: string }>(),
  },
})

export const loadMinionDescendants = createActionGroup({
  source: 'Cic page minions descdendants',
  events: {
    start: props<{ minionId: number; date?: Datestamp }>(),
    success: props<{ navigation: Navigation; minions: MyTeamResponseMinionsInner[]; minionId: number }>(),
    error: props<{ error: string }>(),
  },
})

export const removeMinionDescendants = createAction(
  'Cic page remove minion descendants',
  props<{ minionId: number }>(),
)

export const authAccount = createActionGroup({
  source: 'Auth cic/parent account',
  events: {
    'start': props<{ req: UserIdRequest | null }>(),
    'success': props<{ response: AuthResponse }>(),
    'error': props<{ error: string }>()
  }
})

export const addAccount = createActionGroup({
  source: 'Add cic account',
  events: {
    'start': props<{ req: CicFormRequest }>(),
    'success': props<{ response: SuccessResponse }>(),
    'error': props<{ error: string }>()
  }
})

export const getAvailableParents = createActionGroup({
  source: 'Get list available parents',
  events: {
    'start': emptyProps(),
    'success': props<{ response: AvailableCicParentsResponse }>(),
    'error': props<{ error: string }>()
  }
})

export const setPage = createAction('[Cic page] set page', props<{ newPage: number }>())
export const allErrors = [loadMinionDescendants.error, loadMinions.error]
