import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TopService } from '../api/top.service'
import { loadTopClientService } from './top.actions'

@Injectable()
export class TopEffects {
	constructor(private actions$: Actions, private topService: TopService) {}

	loadMinions = createEffect(() =>
		this.actions$.pipe(
			ofType(loadTopClientService.start),
			mergeMap(() =>
				this.topService.getTopClientService().pipe(
					map(response => loadTopClientService.success(response)),
					catchError(error => of(loadTopClientService.error({ error: String(error) })))
				)
			)
		)
	)
}
