import { isDevMode } from '@angular/core'
import { ActionReducerMap, MetaReducer } from '@ngrx/store'
import { CommonState } from 'common/store/state'
import * as fromShop from './shop/shop.reducer'
import * as fromGeoSearch from 'common/store/geo-search/geo-search.reducer'
import * as fromCart from './checkout/cart/cart.reducer'
import * as fromBoxberry from './checkout/delivery/delivery-office/boxberry/boxberry.reducer'
import * as fromKgPost from './checkout/delivery/delivery-office/kgpost/kgpost.reducer'
import * as fromFargo from './checkout/delivery/delivery-office/fargo/fargo.reducer'
import * as fromCdek from './checkout/delivery/delivery-office/cdek/cdek.reducer'
import * as fromEuropost from './checkout/delivery/delivery-office/europost/europost.reducer'
import * as fromHaypost from './checkout/delivery/delivery-office/haypost/haypost.reducer'
import * as fromKazpost from './checkout/delivery/delivery-office/kazpost/kazpost.reducer'
import * as fromDpd from './checkout/delivery/delivery-office/dpd/dpd.reducer'
import * as fromRupost from './checkout/delivery/delivery-office/rupost/rupost.reducer'
import * as fromDeliveryIsCourier from './checkout/delivery/delivery-is-courier/delivery-is-courier.reducer'
import * as fromAuth from './auth/auth.reducer'
import * as fromPay from './checkout/pay/pay.reducer'
import * as fromOrders from 'common/store/orders/orders.reducer'
import * as fromRegisterPerson from '../features/Registration/register-person'
import * as fromApplyToken from '../features/checkout/apply-referrer-token/model/apply-token.reducer'
import * as fromTeam from '../widgets/account/team-table/model/team.reducer'
import * as fromCic from '../pages/account/cic-page/store/cic.reducer'
import * as fromAddresses from '../features/saved-addresses/model/saved-addresses.reducer'
import * as fromReferral from '../features/Registration/referral/model/register-via-referral.reducer'
import * as fromDeliveryCountries from '../features/geo-search/country-search/model/countries.reducer'
import * as fromGroupVolume from '../widgets/account/group-volume-table/model/group-volume.reducer'
import * as fromPersonalVolume from '../widgets/account/personal-volume-table/model/personal-volume.reducer'
import * as fromTransfersHistory from '../widgets/account/transfer-bonus/model/transfer-history.reducer'
import * as fromTransferBonus from '../widgets/account/transfer-bonus/model/transfer-bonus.reducer'
import * as fromStockSelector from '../widgets/Stock/model/stock.reducer'
import * as fromPartner from '../pages/account/partner-page/model/partner.reducer'
import * as fromTicketsTable from '../widgets/support/tickets-table/model/tickets-table.reducer'
import * as fromBonusInfo from '../widgets/checkout/bonus-info-after-order/model/bonus-info.reducer'
import * as fromConfig from 'client/src/app/model/config.reducer'
import * as fromCreateTicket from '../pages/account/support-page/create-ticket-page/model/create-ticket.reducer'
import * as fromTicket from '../pages/account/support-page/ticket-page/model/ticket.reducer'
import * as fromTotalIncome from '../pages/account/total-income-page/model/total-income.reducer'
import * as fromFivePost from './checkout/delivery/delivery-office/five-post/five-post.reducer'
import * as fromBecomePartner from '../features/account/become-partner/model/become-partner.reducer'
import * as fromTopGrades from '../widgets/top-grades-table/model/top.reducer'
import * as fromTopSallers from '../widgets/top-sallers-table/model/top.reducer'
import * as fromTopRecruiters from '../widgets/top-recruiters-table/model/top.reducer'
import * as fromTopClientService from '../widgets/top-client-service-table/model/top.reducer'
import * as fromReviewList from '../widgets/review/model/review-list.reducer'
import * as fromRegion from '../features/geo-search/region-search/model/region.reducer'
import * as fromNewsList from '../widgets/news/model/news.reducer'
import * as fromActiveEventList from '../widgets/events/active-events-list/model/events.reducer'
import * as fromArchiveEventList from '../widgets/events/archive-events-list/model/events.reducer'
import * as fromEventItem from '../widgets/events/event-item/model/events.reducer'
import * as fromContacts from '../widgets/geography/store/geography-page.reducer'
import * as fromNewPromo from '../pages/new-promo/store/new-promo.reducer'
import * as fromLoading from './loading/loading.reducer'
import * as fromThegame from '../pages/thegame-page/store/thegame.reducer'

export interface State {
	[fromShop.shopFeatureKey]: fromShop.State
	[fromCart.cartFeatureKey]: fromCart.State
	[fromGeoSearch.featureKey]: fromGeoSearch.State
	[fromBoxberry.boxberryFeatureKey]: fromBoxberry.State
	[fromKgPost.kgpostFeatureKey]: fromKgPost.State
	[fromFargo.fargoFeatureKey]: fromFargo.State
	[fromCdek.cdekFeatureKey]: fromCdek.State
	[fromEuropost.europostFeatureKey]: fromEuropost.State
	[fromHaypost.haypostFeatureKey]: fromHaypost.State
	[fromKazpost.kazpostFeatureKey]: fromKazpost.State
	[fromDpd.dpdFeatureKey]: fromDpd.State
	[fromRupost.rupostFeatureKey]: fromRupost.State
	[fromDeliveryIsCourier.deliveryIsCourierFeatureKey]: fromDeliveryIsCourier.State
	[fromAuth.authFeatureKey]: fromAuth.State
	[fromPay.payFeatureKey]: fromPay.State
	[fromOrders.ordersFeatureKey]: fromOrders.State
	[fromRegisterPerson.registerPersonFeatureKey]: fromRegisterPerson.State
	[fromTeam.teamFeatureKey]: fromTeam.State
	[fromCic.cicFeatureKey]: fromCic.State
	[fromAddresses.savedAddressesFeatureKey]: fromAddresses.State
	[fromDeliveryCountries.countriesFeatureKey]: fromDeliveryCountries.State
	[fromReferral.registerViaReferralFeatureKey]: fromReferral.State
	[fromGroupVolume.featureKey]: fromGroupVolume.State
	[fromPersonalVolume.featureKey]: fromPersonalVolume.State
	[fromTransfersHistory.featureKey]: fromTransfersHistory.State
	[fromTransferBonus.featureKey]: fromTransferBonus.State
	[fromStockSelector.featureKey]: fromStockSelector.State
	[fromApplyToken.featureKey]: fromApplyToken.State
	[fromPartner.featureKey]: fromPartner.State
	[fromConfig.featureKey]: fromConfig.State
	[fromTicketsTable.featureKey]: fromTicketsTable.State
	[fromCreateTicket.featureKey]: fromCreateTicket.State
	[fromTicket.featureKey]: fromTicket.State
	[fromTotalIncome.featureKey]: fromTotalIncome.State
	[fromBonusInfo.featureKey]: fromBonusInfo.State
	[fromFivePost.fivePostFeatureKey]: fromFivePost.State
	[fromBecomePartner.featureKey]: fromBecomePartner.State
	[fromTopGrades.featureKey]: fromTopGrades.State
	[fromTopSallers.featureKey]: fromTopSallers.State
	[fromTopRecruiters.featureKey]: fromTopRecruiters.State
	[fromTopClientService.featureKey]: fromTopClientService.State
	[fromReviewList.reviewListFeatureKey]: fromReviewList.State
	[fromRegion.regionFeatureKey]: fromRegion.State
	[fromNewsList.featureKey]: fromNewsList.State
	[fromActiveEventList.featureKey]: fromActiveEventList.State
	[fromArchiveEventList.featureKey]: fromArchiveEventList.State
	[fromEventItem.featureKey]: fromEventItem.State
	[fromContacts.contactsFeatureKey]: fromContacts.State
	[fromNewPromo.newPromoFeatureKey]: fromNewPromo.State
	[fromLoading.loadingFeatureKey]: fromLoading.LoadingState,
	[fromThegame.thegameFeatureKey]: fromThegame.State
}

export interface AppState extends CommonState, State {}

export const reducers: ActionReducerMap<State> = {
	[fromShop.shopFeatureKey]: fromShop.reducer,
	[fromCart.cartFeatureKey]: fromCart.reducer,
	[fromGeoSearch.featureKey]: fromGeoSearch.reducer,
	[fromBoxberry.boxberryFeatureKey]: fromBoxberry.reducer,
	[fromKgPost.kgpostFeatureKey]: fromKgPost.reducer,
	[fromFargo.fargoFeatureKey]: fromFargo.reducer,
	[fromCdek.cdekFeatureKey]: fromCdek.reducer,
	[fromEuropost.europostFeatureKey]: fromEuropost.reducer,
	[fromHaypost.haypostFeatureKey]: fromHaypost.reducer,
	[fromKazpost.kazpostFeatureKey]: fromKazpost.reducer,
	[fromDpd.dpdFeatureKey]: fromDpd.reducer,
	[fromRupost.rupostFeatureKey]: fromRupost.reducer,
	[fromDeliveryIsCourier.deliveryIsCourierFeatureKey]: fromDeliveryIsCourier.reducer,
	[fromAuth.authFeatureKey]: fromAuth.reducer,
	[fromPay.payFeatureKey]: fromPay.reducer,
	[fromOrders.ordersFeatureKey]: fromOrders.reducer,
	[fromRegisterPerson.registerPersonFeatureKey]: fromRegisterPerson.reducer,
	[fromTeam.teamFeatureKey]: fromTeam.reducer,
	[fromCic.cicFeatureKey]: fromCic.reducer,
	[fromAddresses.savedAddressesFeatureKey]: fromAddresses.reducer,
	[fromReferral.registerViaReferralFeatureKey]: fromReferral.reducer,
	[fromDeliveryCountries.countriesFeatureKey]: fromDeliveryCountries.reducer,
	[fromGroupVolume.featureKey]: fromGroupVolume.reducer,
	[fromPersonalVolume.featureKey]: fromPersonalVolume.reducer,
	[fromTransfersHistory.featureKey]: fromTransfersHistory.reducer,
	[fromTransferBonus.featureKey]: fromTransferBonus.reducer,
	[fromStockSelector.featureKey]: fromStockSelector.reducer,
	[fromApplyToken.featureKey]: fromApplyToken.reducer,
	[fromPartner.featureKey]: fromPartner.reducer,
	[fromConfig.featureKey]: fromConfig.reducer,
	[fromTicketsTable.featureKey]: fromTicketsTable.reducer,
	[fromCreateTicket.featureKey]: fromCreateTicket.reducer,
	[fromTicket.featureKey]: fromTicket.reducer,
	[fromTotalIncome.featureKey]: fromTotalIncome.reducer,
	[fromBonusInfo.featureKey]: fromBonusInfo.reducer,
	[fromFivePost.fivePostFeatureKey]: fromFivePost.reducer,
	[fromBecomePartner.featureKey]: fromBecomePartner.reducer,
	[fromTopGrades.featureKey]: fromTopGrades.reducer,
	[fromTopSallers.featureKey]: fromTopSallers.reducer,
	[fromTopRecruiters.featureKey]: fromTopRecruiters.reducer,
	[fromTopClientService.featureKey]: fromTopClientService.reducer,
	[fromReviewList.reviewListFeatureKey]: fromReviewList.reducer,
	[fromRegion.regionFeatureKey]: fromRegion.reducer,
	[fromNewsList.featureKey]: fromNewsList.reducer,
	[fromActiveEventList.featureKey]: fromActiveEventList.reducer,
	[fromArchiveEventList.featureKey]: fromArchiveEventList.reducer,
	[fromEventItem.featureKey]: fromEventItem.reducer,
	[fromContacts.contactsFeatureKey]: fromContacts.reducer,
	[fromNewPromo.newPromoFeatureKey]: fromNewPromo.reducer,
	[fromLoading.loadingFeatureKey]: fromLoading.reducer,
	[fromNewPromo.newPromoFeatureKey]: fromNewPromo.reducer,
	[fromThegame.thegameFeatureKey]: fromThegame.reducer
}

export const metaReducers: MetaReducer<AppState>[] = isDevMode() ? [] : []
