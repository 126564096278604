import { Injectable } from '@angular/core'
import { disableJwtInterception, JwtApi, JwtGroup, JwtInfo } from 'ngx-customapp-jwt'
import { authHeader, authResponseToJwt, Credentials } from '../utils/user/auth'
import { map, Observable, tap } from 'rxjs'
import { RequestService } from './request.service'
import { HttpContext } from '@angular/common/http'
import { AuthResponse } from 'common/models/auth-response'
import { AuthRequest } from 'common/models/auth-request'
import { assert, unreachable } from 'ngx-customapp-errors'
import { Store } from '@ngrx/store'
import { loginSuccess } from '../store/login.action'

@Injectable({
	providedIn: 'root'
})
export class JwtApiService implements JwtApi<Credentials, AuthResponse> {
	constructor(private request: RequestService, private store: Store) {}

	login(credentials: Credentials): Observable<AuthResponse> {
		const context = new HttpContext().set(disableJwtInterception, true)
		if (credentials.type === 'password') {
			return this.request
				.post<AuthRequest, AuthResponse>(
					'/api/auth',
					{
						access_parameter: credentials.data.login,
						password: credentials.data.password
					},
					{ context }
				)
				.pipe(
					tap(data => {
						if (data) {
							this.store.dispatch(loginSuccess({ user: data }))
						}
					})
				)
		} else if (credentials.type === 'token') {
			return this.request
				.post<null, AuthResponse>('/api/auth/by_token', null, {
					context,
					headers: {
						[authHeader.name]: authHeader.createValue(credentials.data)
					}
				})
				.pipe(
					tap(data => {
						if (data) {
							this.store.dispatch(loginSuccess({ user: data }))
						}
					})
				)
		} else {
			return unreachable('unknown credentials type')
		}
	}

	logout(accessToken: JwtInfo, fromAllDevices: boolean | undefined): Observable<void> {
		const options = {
			context: new HttpContext().set(disableJwtInterception, true),
			headers: {
				[authHeader.name]: authHeader.createValue(accessToken)
			}
		}
		return fromAllDevices
			? this.request.post('/api/auth/full_logout', null, options)
			: this.request.post('/api/auth/logout', null, options)
	}

	refresh(refreshToken: JwtInfo): Observable<JwtGroup<JwtInfo>> {
		return this.request
			.post<null, AuthResponse>('/api/auth/refresh', null, {
				context: new HttpContext().set(disableJwtInterception, true),
				headers: {
					[authHeader.name]: authHeader.createValue(refreshToken)
				}
			})
			.pipe(
				map(resp => {
					const jwt = authResponseToJwt(resp)
					assert(jwt, 'missing jwt in refresh response from backend')
					return jwt
				})
			)
	}
}
