import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppState } from '../../../store/state'
import { ThegameService } from '../thegame.service'
import { catchError, map, mergeMap, of } from 'rxjs'
import { loadThegameData } from './thegame.actions'

@Injectable()
export class ThegameEffects {
	constructor(private actions$: Actions, private thegameService: ThegameService, private store: Store<AppState>) {}

	getData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadThegameData.start),
			mergeMap(() =>
				this.thegameService.getData().pipe(
					map(data => {
						return loadThegameData.success({ data })
					}),
					catchError(error => of(loadThegameData.error({ error: String(error) })))
				)
			)
		)
	)
}
