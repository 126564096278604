import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { familyAgreementCreateBoth, familyAgreementCreateOne } from './family-agreement.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { FamilyAgreementService } from './family-agreement.service'

@Injectable()
export class FamilyAgreementEffects {
  createOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(familyAgreementCreateOne.start),
      mergeMap(({ req }) =>
        this.familyAgreementService.createOne(req).pipe(
          map(() => familyAgreementCreateOne.success()),
          catchError(error => of(familyAgreementCreateOne.error({ error }))),
        ),
      ),
    ),
  )

  createBoth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(familyAgreementCreateBoth.start),
      mergeMap(({ req }) =>
        this.familyAgreementService.createBoth(req).pipe(
          map(() => familyAgreementCreateBoth.success()),
          catchError(error => of(familyAgreementCreateBoth.error({ error }))),
        ),
      ),
    ),
  )
  constructor(private actions$: Actions, private familyAgreementService: FamilyAgreementService) {}
}
