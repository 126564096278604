import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
	approveGroupOrder,
	createGroupOrder,
	loadGroupOrderInfo,
	loadOrders,
	loadOrdersAdmin,
	PAGE_SIZE
} from './orders.actions'
import { catchError, concatMap, map, mergeMap, of, switchMap, tap } from 'rxjs'
import { RequestService } from 'common/services/request.service'
import { OrdersByIntIdRequest } from '../../models/orders-by-int-id-request'
import { OrderListResponse } from '../../models/order-list-response'
import { ConstraintsOrdersRequest } from 'common/models/constraints-orders-request'
import { GroupOrderRequest } from 'common/models/group-order-request'
import { Order } from 'common/models/order'
import { MatDialog } from '@angular/material/dialog'
import { NotifyService } from 'client/src/app/services/notify.service'
import { Router } from '@angular/router'

@Injectable()
export class OrdersEffects {
	private pageSize = PAGE_SIZE
	constructor(
		private actions$: Actions,
		private requestService: RequestService,
		private dialogRef: MatDialog,
		private notifyService: NotifyService,
		private router: Router
	) {}

	createGroupOrderSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createGroupOrder.success),
				concatMap(resp =>
					this.router.navigateByUrl('checkout/order/' + resp.order.id).then(() => {
						this.notifyService.success('Групповой заказ успешно был создан')
					})
				)
			),
		{ dispatch: false }
	)

	createGroupOrderError$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createGroupOrder.error),
				tap(action => {
					this.notifyService.error(action.error)
				})
			),
		{ dispatch: false }
	)

	createGroupOrder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createGroupOrder.start),
			mergeMap(({ req }) =>
				this.createGroupOrderRequest(req).pipe(
					map(order => createGroupOrder.success({ order })),
					catchError(error => of(createGroupOrder.error({ error: String(error) })))
				)
			)
		)
	)

	loadGroupOrderInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadGroupOrderInfo.start),
			mergeMap(({ id }) =>
				this.getGroupOrderInfo(id).pipe(
					map(order => loadGroupOrderInfo.success({ order })),
					catchError(error => of(loadGroupOrderInfo.error({ error: String(error) })))
				)
			)
		)
	)

	loadGroupOrderInfoError$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(loadGroupOrderInfo.error),
				tap(action => {
					this.notifyService.error('Групповой заказ не найден')
				})
			),
		{ dispatch: false }
	)

	loadOrders$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadOrders.start),
			mergeMap(({ req }) =>
				this.getOrders(req).pipe(
					map(({ orders, navigation }) =>
						loadOrders.success({ orders, navigation, int_id: req.order_int_id })
					),
					catchError(error => of(loadOrders.error({ error: String(error) })))
				)
			)
		)
	)

	loadOrdersAdmin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadOrdersAdmin.start),
			mergeMap(({ req }) =>
				this.getOrdersAdmin(req).pipe(
					map(({ orders, navigation }) => loadOrdersAdmin.success({ orders, navigation })),
					catchError(error => of(loadOrdersAdmin.error({ error: String(error) })))
				)
			)
		)
	)

	private getGroupOrderInfo(id: number) {
		return this.requestService.get<Order>('api/orders/group_order/' + id.toString())
	}

	private createGroupOrderRequest(req: GroupOrderRequest) {
		return this.requestService.post<GroupOrderRequest, Order>('api/orders/group_order', req)
	}

	private getOrders(req: OrdersByIntIdRequest) {
		return this.requestService.post<OrdersByIntIdRequest, OrderListResponse>(
			'api/orders/get/list/by_int_id_v2',
			req
		)
	}

	private getOrdersAdmin(req: ConstraintsOrdersRequest) {
		return this.requestService.post<ConstraintsOrdersRequest, OrderListResponse>('api/orders/full_search', req)
	}
}
