import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { recoverPassword } from './auth.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { AuthService } from '../api/auth.service'

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  recoverPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recoverPassword.start),
      mergeMap(({ phone }) =>
        this.authService.sendSMSForPasswordRecover(phone).pipe(
          map(() => recoverPassword.success()),
          catchError(e => of(recoverPassword.error({ error: String(e) }))),
        ),
      ),
    ),
  )
}
