import { HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Item } from 'common/models/item'
import { ProductWithItems } from 'common/models/product-with-items'
import { RequestService } from 'common/services/request.service'
import { disableJwtInterception } from 'ngx-customapp-jwt'
import { Observable, forkJoin } from 'rxjs'
import { defaultCountryCode } from 'common/utils/currency/default-currency'
import { SuccessResponse } from 'common/models/success-response'

type Category = string

@Injectable({
	providedIn: 'root'
})
export class ShopService {
	constructor(private readonly requestService: RequestService) {}

	/** Set field *context* to this value to disable JWT auth */
	private disableJWT() {
		return new HttpContext().set(disableJwtInterception, true)
	}

	public getProducts() {
		let countryId = defaultCountryCode()
		return this.requestService.post<Record<string, never>, ProductWithItems[]>(
			'api/products/list?country_id=' + countryId,
			{}
		)
	}

	public getCategories() {
		let countryId = defaultCountryCode()
		return this.requestService.post<Record<string, never>, Category[]>(
			'api/products/categories?country_id=' + countryId,
			{}
		)
	}

	public getProductsAndCategories() {
		return forkJoin([this.getProducts(), this.getCategories()])
	}

	public getSingleProduct(slug: string, stock_id: number): Observable<ProductWithItems> {
		return this.requestService.get<ProductWithItems>(`api/products/${slug}?stock_id=` + stock_id, {})
	}

	public getSingleItem(id: number): Observable<Item> {
		return this.requestService.post<unknown, Item>(`api/items/${id}`, {})
	}

	public addFavoriteProduct(productId: number): Observable<SuccessResponse> {
		return this.requestService.post<unknown, SuccessResponse>('api/products/favourites', { productId: productId })
	}

	public deleteFavoriteProduct(productId: number): Observable<SuccessResponse> {
		return this.requestService.delete<unknown, SuccessResponse>(`api/products/favourites/${productId}`, {})
	}
}
