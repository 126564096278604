// ДОЛЖНО БЫТЬ СИНХРОНИЗИРОВАНО С breakpoints.scss
// лежат рядом со стилями, чтобы не забыть поменять, если в стилях поменяем

export const breakpoints = {
  xl: 1400,
  lg: 1024,
  md: 768,
  sm: 640,
  xs: 480,
} as const

export type Breakpoints = keyof typeof breakpoints
