import { createReducer, on } from '@ngrx/store'
import { getGroupAccruals } from './group-volume.actions'
import { AccrualsGroupVolumesListResponse } from 'common/models/accruals-group-volumes-list-response'

export const featureKey = 'group volume'

export interface State {
  loading: boolean
  response: AccrualsGroupVolumesListResponse | null
}

export const initialState: State = {
  loading: false,
  response: null,
}

export const reducer = createReducer(
  initialState,
  on(getGroupAccruals.start, state => {
    return { ...state, loading: true }
  }),
  on(getGroupAccruals.success, (state, response) => {
    return { ...state, response, loading: false }
  }),
  on(getGroupAccruals.error, state => {
    return { ...state, loading: false }
  }),
)
