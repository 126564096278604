import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { RegistrationModalComponent } from 'client/src/features/Registration/registration-modal/registration-modal.component'
import { AppState } from 'client/src/store/state'
import { RegRequest } from 'common/models/reg-request'
import { provideNgxMask } from 'ngx-mask'
import { Subject, takeUntil } from 'rxjs'
import { registerPerson, selectRegisterPersonLoading } from '../model'
import { SuccessModalComponent } from '../../../../shared/components/success-modal/success-modal.component'
import { BreakpointService } from '../../../../app/services/breakpoint.service'

@Component({
  selector: 'app-register-person-modal',
  standalone: true,
  imports: [CommonModule, RegistrationModalComponent],
  templateUrl: './register-person-modal.component.html',
  styleUrls: ['./register-person-modal.component.scss'],
  providers: [provideNgxMask()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPersonModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<RegisterPersonModalComponent>,
    private store: Store<AppState>,
    private actions$: Actions,
    private dialog: MatDialog,
    private bp: BreakpointService,
  ) {}

  isSmDown$ = this.bp.down('sm')

  private _ngDestroy$ = new Subject<void>()

  ngOnInit() {
    this.actions$
      .pipe(takeUntil(this._ngDestroy$))
      .pipe(ofType(registerPerson.success))
      .subscribe(({ data, who }) => {
        this.dialog.open(SuccessModalComponent, {
          data: {
            description:
              `${who === 'partner' ? 'Партнер' : 'Клиент'} зарегистрирован.
                          Пароль для входа выслан на номер телефона <b>${data.user_data.phone}</b>` +
              (data.user_data.email ? ` и email <b>${data.user_data.email}</b>` : ''),
          },
        })
        this.closeDialog()
      })
  }

  loading$ = this.store.select(selectRegisterPersonLoading)

  ngOnDestroy(): void {
    this._ngDestroy$.next()
    this._ngDestroy$.complete()
  }

  closeDialog() {
    this.dialogRef.close()
  }

  handleSubmit(form: { data: RegRequest; who: 'client' | 'partner' | null }) {
    if (!form.who) return

    this.store.dispatch(
      registerPerson.start({
        data: form.data,
        who: form.who,
      }),
    )
  }
}
