import {Injectable} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {RegisterPersonModalComponent} from 'client/src/features/Registration/register-person'
import {
	openRegisterPersonDialog
} from 'client/src/widgets/account/referral-program-controls/model/open-register-person-dialog.action'
import {filter, map, switchMap} from 'rxjs'
import {DowngradeComponent} from '../../features/auth/downgrade/downgrade.component'
import {loginSuccess} from '../../../../common/store/login.action'
import {
	getLocalStorageDeferralDate,
	isNewMothBeginForDeferralDate
} from '../../features/auth/downgrade/downgrade.helper'

@Injectable()
export class LayoutEffects {
	constructor(private actions$: Actions, private dialog: MatDialog) {
	}

	handleRegisterPersonModal$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openRegisterPersonDialog),
				map(() =>
					this.dialog.open(RegisterPersonModalComponent, {
						width: '600px',
						maxWidth: '100%',
						maxHeight: '95vh'
					})
				)
			),
		{dispatch: false}
	)

	handleDowngradeModal$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(loginSuccess),
				filter(res => !!res.user?.client_partner?.main_user_data?.awaiting_role_downgrade),
				map(res => {
					return res.user?.client_partner?.main_user_data
				}),
				filter(user => {
					const deferralDate = user?.id && getLocalStorageDeferralDate(user.id)
					return deferralDate ? isNewMothBeginForDeferralDate(deferralDate) : true
				}),
				switchMap(res =>
					this.dialog
						.open(DowngradeComponent, {
							width: '550px',
							maxWidth: '100%',
							maxHeight: '95vh',
							data: {userId: res?.id}
						})
						.afterClosed()
				)
			),
		{dispatch: false}
	)
}
