import { createAction, props } from '@ngrx/store'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'
import { DPDOffice } from '../../../../../../../common/models/dpd-office'
import { errorProps } from '../../../../../../../common/utils/store/types'

export const dpdLoad = createAction('[DPD] load', cityIdProps)

export const dpdLoadSuccess = createAction('[DPD] load success', props<{ data: DPDOffice[] }>())

export const dpdLoadError = createAction('[DPD] load error', errorProps)

export const dpdRemove = createAction('[DPD] remove')
