<input
  type="text"
  matInput
  #internalInput
  [formControl]="regionControl"
  [disabled]="isDisabled"
  [matAutocomplete]="autocomplete"
  (focusin)="onFocusIn()"
  (blur)="handleBlur()"
  [placeholder]="placeholder"
  [required]="required"
/>
<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayFn" (optionSelected)="handleSelect($event)">
  <mat-option *ngIf="notFound$ | async" disabled> Регион не найден </mat-option>
  <mat-option *ngFor="let option of regions$ | async" [value]="option">
    {{ option.name }}
  </mat-option>
</mat-autocomplete>
