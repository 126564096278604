import { Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule } from '@angular/forms'
import { BehaviorSubject, combineLatestWith, map, Observable, Subject } from 'rxjs'
import { Country } from '../../../../../common/models/country'
import { MatInputModule } from '@angular/material/input'
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatFormFieldControl } from '@angular/material/form-field'
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'

@Component({
  selector: 'app-country-search',
  standalone: true,
  imports: [CommonModule, MatInputModule, ReactiveFormsModule, MatAutocompleteModule],
  templateUrl: './country-search.component.html',
  styleUrls: ['./country-search.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CountrySearchComponent,
    },
  ],
})
export class CountrySearchComponent implements OnDestroy, ControlValueAccessor, MatFormFieldControl<Country> {
  constructor(
    @Optional() @Self() public ngControl: NgControl, // need this to implement MatFormFieldControl
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this
    }
  }

  @Input() set countries(v: Country[] | undefined | null) {
    this.countriesSubject$.next(v || [])
  }

  private countriesSubject$ = new BehaviorSubject<Country[]>([])

  // string is for user input, Country is for externally set value
  control = new FormControl<string | Country | undefined>(undefined)

  private country: Country | undefined

  isDisabled = false

  options$: Observable<Country[]> = this.countriesSubject$.pipe(
    combineLatestWith(this.control.valueChanges),
    map(([countries, value]) => {
      if (typeof value === 'string') {
        return countries.filter(country => country.name?.toLowerCase().includes(value.toLowerCase()))
      }
      return countries
    }),
  )

  displayFn(country: Country): string {
    return country?.name ?? ''
  }

  handleSelect(event: MatAutocompleteSelectedEvent) {
    this.country = event.option.value as Country
    this.change(this.country)
  }

  handleBlur() {
    // это нужно, чтобы откатить изменения, если юзер стер часть названия города, а потом не выбрал новый.
    this.control.setValue(this.country, { emitEvent: false })
    this.touched()
    this.onFocusOut()
  }

  // MatFormFieldControl fields

  @ViewChild('internalInput') internalInput?: ElementRef<HTMLInputElement>

  @Input()
  set value(country: Country | null) {
    this.writeValue(country)
  }

  get value(): Country | null {
    return this.country || null
  }

  stateChanges = new Subject<void>()

  static nextId = 0

  @HostBinding('id')
  id = `app-country-search-${CountrySearchComponent.nextId++}`

  @Input() set placeholder(plh: string) {
    this._placeholder = plh
    this.stateChanges.next()
  }

  get placeholder(): string {
    return this._placeholder || ''
  }

  private _placeholder: string | undefined

  ngOnDestroy() {
    this.stateChanges.complete()
  }

  // that's just a copy and paste from https://material.angular.io/guide/creating-a-custom-form-field-control
  onFocusIn() {
    if (!this.focused) {
      this.focused = true
      this.stateChanges.next()
    }
  }

  // called inside blur, cos blur usually indicates that whole input loses focus
  // usage of (focusout) is not desirable, cos we have autocomplete, which also can take focus
  private onFocusOut() {
    this.focused = false
    this.stateChanges.next()
  }

  focused = false

  get empty(): boolean {
    return !this.country
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty
  }

  @Input()
  get required(): boolean {
    return this._required
  }

  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req)
    this.stateChanges.next()
  }

  private _required = false

  @Input()
  get disabled(): boolean {
    return this.isDisabled
  }

  set disabled(value: BooleanInput) {
    this.setDisabledState(coerceBooleanProperty(value))
  }

  get errorState(): boolean {
    return (this.ngControl.invalid && this.ngControl.touched) || false
  }

  controlType = 'app-country-search'

  setDescribedByIds(): void {
    // not implemented, cos I don't really know why it should
    // control value accessor never cares about such things
    return
  }

  onContainerClick() {
    this.internalInput?.nativeElement?.focus()
  }

  // ControlValueAccessor methods
  private onChangeFn?: (value: Country | undefined) => void
  private onTouchedFn?: () => void

  registerOnChange(fn: (value: Country | undefined) => void): void {
    this.onChangeFn = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled
    this.stateChanges.next()
  }

  writeValue(value: Country | undefined | null): void {
    this.country = value || undefined
    this.control.setValue(value, { emitEvent: false })
    this.stateChanges.next()
  }

  change(value: Country | undefined) {
    this.onChangeFn?.(value)
    this.touched()
    this.stateChanges.next()
  }

  touched() {
    this.onTouchedFn?.()
  }
}
