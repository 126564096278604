import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TicketService } from '../api/ticket.service'
import { updateTicketStatus, loadTicketWithMessages, sendMessage } from './ticket.actions'

@Injectable()
export class TicketEffects {
  constructor(private actions$: Actions, private ticketsTableService: TicketService) {}

  loadTicketWithMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTicketWithMessages.start),
      mergeMap(({ id }) =>
        this.ticketsTableService.getTicketWithMessages(id).pipe(
          map(response => loadTicketWithMessages.success({ ticketWithMessage: response })),
          catchError(error => of(loadTicketWithMessages.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessage.start),
      mergeMap(({ message, ticketId }) =>
        this.ticketsTableService.sendMessage(ticketId, message).pipe(
          map(response => sendMessage.success({ ticket: response })),
          catchError(error => of(sendMessage.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  closeTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTicketStatus.start),
      mergeMap(({ ticket }) =>
        this.ticketsTableService.update(ticket).pipe(
          map(resp => updateTicketStatus.success({ ticket: resp })),
          catchError(error => of(updateTicketStatus.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
