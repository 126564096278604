import { createActionGroup, emptyProps, props } from '@ngrx/store'

export const loadThegameData = createActionGroup({
	source: '[Thegame] Get Thegame Data',
	events: {
		start: emptyProps(),
		success: props<{ data: any }>(),
		error: props<{ error: string }>()
	}
})
