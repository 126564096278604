import { createReducer, on } from '@ngrx/store'
import { MyTeamResponseMinionsInner } from 'common/models/my-team-response-minions-inner'
import { Navigation } from 'common/models/navigation'
import {
  applyFiltersAndResetPage,
  loadMinionDescendants,
  loadMinions,
  removeMinionDescendants,
  resetFiltersAndPage,
  setDirtyStatus,
  setPage,
} from './team.actions'
import { MinionFromMyTeamResponse } from 'common/models/minion-from-my-team-response'
import { Datestamp } from '../../../../../../common/models/datestamp'
import { Filters } from '../api/team.service'

export const teamFeatureKey = 'team'

export interface MinionWithLevelDown {
  minion: MyTeamResponseMinionsInner
  isOwner?: boolean
  /** Field for descendant minions */
  levelDown?: number
  descendantsLoaded?: boolean // чтобы не загружать повторно уже загруженных миньонов
}

const getMinionId = (minion: MinionWithLevelDown): number => minion.minion?.minion_data?.main_user_data?.id ?? 0

export interface State {
  minions: MinionWithLevelDown[]
  navigation: Navigation
  currentMinion: MinionFromMyTeamResponse | null
  partnersAmount: {
    all: number
    active: number
  }
  date?: Datestamp
  areFiltersDirty: boolean
  searchInfo: {
    filters: Filters | null
    page: number
  }
  isLoading: boolean
}

export const initialState: State = {
  minions: [],
  navigation: { page: 0, size: 10, total: 0 },
  currentMinion: null,
  areFiltersDirty: false,
  partnersAmount: {
    all: 0,
    active: 0,
  },
  searchInfo: {
    filters: null,
    page: 0,
  },
  isLoading: false,
}

export const reducer = createReducer(
  initialState,
  on(loadMinions.start, state => ({ ...state, isLoading: true })),
  on(loadMinions.error, state => ({ ...state, isLoading: false })),
  on(loadMinions.success, state => ({ ...state, isLoading: false })),

  on(
    loadMinions.success,
    (state, { date, response: { minions, navigation, number_active_partners, number_partners } }) => {
      return {
        ...state,
        navigation: navigation ?? { page: 0, size: 0, total: 0 },
        minions: (minions ?? []).map(r => ({ minion: r, parentId: undefined })),
        partnersAmount: { all: number_partners ?? 0, active: number_active_partners ?? 0 },
        date,
      }
    },
  ),

  on(loadMinionDescendants.start, state => ({ ...state, isLoading: true })),
  on(loadMinionDescendants.success, state => ({ ...state, isLoading: false })),
  on(loadMinionDescendants.error, state => ({ ...state, isLoading: false })),

  on(loadMinionDescendants.success, (state, { minions, minionId }) => {
    const minionIndexWhoseDescendantsWeGot = state.minions.findIndex(
      ({ minion: { minion_data }, descendantsLoaded }) =>
        minion_data.main_user_data?.id === minionId && !descendantsLoaded,
    )

    // нет такого миньона или его дети уже были загружены
    if (minionIndexWhoseDescendantsWeGot === -1) {
      return state
    }

    const foundMinion: MinionWithLevelDown = {
      ...state.minions[minionIndexWhoseDescendantsWeGot],
      descendantsLoaded: true,
    }

    const newMinions = [...state.minions]
    // заменяем найденного миньона, так как поменяли флаг descendantsLoaded
    newMinions.splice(minionIndexWhoseDescendantsWeGot, 1, foundMinion)
    newMinions.splice(
      minionIndexWhoseDescendantsWeGot + 1,
      0,
      ...minions.map<MinionWithLevelDown>(r => ({
        minion: r,
        levelDown: (foundMinion.levelDown ?? 0) + 1,
      })),
    )

    return { ...state, minions: newMinions }
  }),

  on(removeMinionDescendants, (state, { minionId }) => {
    let minionIndex = state.minions.findIndex(minion => getMinionId(minion) === minionId)
    //  нет такого миньона
    if (minionIndex === -1) {
      return state
    }
    const foundMinion: MinionWithLevelDown = {
      ...state.minions[minionIndex],
      descendantsLoaded: false,
    }
    // сохраняем миньонов до найденного
    const newMinions = state.minions.slice(0, minionIndex)
    // найденный изменился, поэтому его заново пушим
    newMinions.push(foundMinion)

    // удаляем всех миньонов, которые по уровню ниже
    const startLevelDown = state.minions[minionIndex]?.levelDown ?? 0
    do {
      minionIndex++
    } while ((state.minions?.[minionIndex].levelDown ?? 0) > startLevelDown)
    newMinions.push(...state.minions.slice(minionIndex))
    return {
      ...state,
      minions: newMinions,
    }
  }),

  on(setDirtyStatus, (state, { newStatus }) => {
    if (state.areFiltersDirty === newStatus) {
      return state
    }
    return { ...state, areFiltersDirty: newStatus }
  }),

  on(resetFiltersAndPage, state => {
    return { ...state, searchInfo: { ...state.searchInfo, filters: null, page: 0 }, areFiltersDirty: false }
  }),

  on(setPage, (state, { newPage }) => {
    return { ...state, searchInfo: { ...state.searchInfo, page: newPage } }
  }),

  on(applyFiltersAndResetPage, (state, { filters }) => {
    return { ...state, searchInfo: { filters, page: 0 } }
  }),
)
