import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { regionLoad } from './region.actions'
import { RegionService } from './region.service'

@Injectable()
export class RegionEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(regionLoad.start),
      mergeMap(({ countryId, regionName }) =>
        this.regionService.load(countryId, regionName).pipe(
          map(regions => regionLoad.success({ data: regions.regions ?? [] })),
          catchError(error => of(regionLoad.error({ error: error }))),
        ),
      ),
    ),
  )

  constructor(private actions$: Actions, private regionService: RegionService) {}
}
