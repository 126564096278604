import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core'
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { TuiDestroyService } from '@taiga-ui/cdk'
import parsePhoneNumber from 'libphonenumber-js'
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import { takeUntil } from 'rxjs'
import { translations } from '../../../../../common/utils/constants/translations';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-international-phone-input',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMaskDirective,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './international-phone-input.component.html',
  styleUrls: ['./international-phone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InternationalPhoneInputComponent),
      multi: true,
    },
    provideNgxMask(),
    TuiDestroyService,
  ],
})
export class InternationalPhoneInputComponent implements OnInit, ControlValueAccessor, OnChanges {
  constructor(private _ngDestroy$: TuiDestroyService) {}

  @Input() error: string | null = null
  @Input() label = translations.number

  get inputMessageError() {
    return this.error ?? this.phoneInputForm.errors?.['format'] ?? null
  }

  phoneInputForm = new FormControl('')
  _onChange?: (_: any) => void
  _onTouched?: (_: any) => void
  country: string | null = null

  writeValue(value: string): void {
    this.phoneInputForm.setValue(value, { emitEvent: false })
    this.setCountryFromValueAndValidate(value)
  }

  registerOnChange(fn: any): void {
    this._onChange = fn
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneInputForm.disable()
    } else {
      this.phoneInputForm.enable()
    }
  }

  private setCountryFromValueAndValidate(value: string) {
    const country = this.getCountry(value)
    const errors = { ...this.phoneInputForm.errors }
    if (country) {
      delete errors['format']
      const errorToSet = Object.values(errors).length > 0 ? errors : null

      this.phoneInputForm.setErrors(errorToSet)
    } else {
      this.phoneInputForm.setErrors({ ...errors, format: translations.invalid_format })
    }
    this.country = country
  }

  private getCountry(value: string): string | null {
    const parsedPhone = parsePhoneNumber('+' + value)
    return parsedPhone?.country ? parsedPhone?.country.toLowerCase() : null
  }

  ngOnInit(): void {
    this.phoneInputForm.valueChanges.pipe(takeUntil(this._ngDestroy$)).subscribe(value => {
      this.setCountryFromValueAndValidate(value ?? '')
      this._onChange && this._onChange(value)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const errorMessage = changes['errorMessage']?.currentValue as string | null | undefined
    this.handleError(errorMessage)
  }

  private handleError(errorMessage?: string | null) {
    if (errorMessage === undefined) {
      return
    }

    if (errorMessage) {
      this.phoneInputForm.setErrors({ error: errorMessage })
    } else {
      this.phoneInputForm.setErrors(null)
    }
  }
}
