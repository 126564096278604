import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { CheckRefParameterResponse } from 'common/models/check-ref-parameter-response'
import { RegRefRequest } from 'common/models/reg-ref-request'

export const registerViaReferral = createActionGroup({
  source: 'Register via referral modal',
  events: {
    start: props<{ data: RegRefRequest; who: 'client' | 'partner' }>(),
    success: props<{ data: RegRefRequest; who: 'client' | 'partner' }>(),
    error: props<{ error: string }>(),
  },
})

export const getUserInfoByToken = createActionGroup({
  source: 'Get user info by token',
  events: {
    start: props<{ token: string }>(),
    success: props<{ response: CheckRefParameterResponse }>(),
    error: props<{ error: string }>(),
  },
})

export const clearTokenInfo = createAction('Clear user info that is got by ref token')

export const allErrors = [registerViaReferral.error, getUserInfoByToken.error]
