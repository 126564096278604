import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { loadCities, loadCountries, loadRegions } from './geo-search.actions'
import { GeoSearchService } from './geo-search.service'

@Injectable()
export class GeoSearchEffects {
  constructor(private actions$: Actions, private geoSearchService: GeoSearchService) {}

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCountries.start),
      mergeMap(() =>
        this.geoSearchService.getCountries().pipe(
          map(countries => loadCountries.success({ countries: countries })),
          catchError(error => of(loadCountries.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  loadRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRegions.start),
      mergeMap(({ name, countryId }) =>
        this.geoSearchService.searchRegion(name, { countryId }).pipe(
          map(({ regions }) => loadRegions.success({ regions: regions ?? [] })),
          catchError(error => of(loadRegions.error({ error: String(error) }))),
        ),
      ),
    ),
  )

  loadCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCities.start),
      mergeMap(({ name, countryId, regionId }) =>
        this.geoSearchService.searchCity(name, { countryId, regionId }).pipe(
          map(({ cities }) => loadCities.success({ cities: cities ?? [] })),
          catchError(error => of(loadCities.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
