import { Injectable } from '@angular/core'
import { PurchaseSettlementResponse } from 'common/models/purchase-settlement-response'
import { RequestService } from 'common/services/request.service'
import { PartialPayment } from '../../../../../../common/models/partial-payment'

@Injectable({ providedIn: 'root' })
export class BonusInfoService {
  constructor(private requestService: RequestService) {}

  getInfo(req: PartialPayment) {
    return this.requestService.post<PartialPayment, PurchaseSettlementResponse>('/api/orders/calculation', req)
  }
}
