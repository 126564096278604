import { Injectable } from '@angular/core'
import { FundsTransferListResponse } from 'common/models/funds-transfer-list-response'
import { RequestService } from 'common/services/request.service'

export const pageSize = 100

@Injectable({ providedIn: 'root' })
export class TransferHistoryService {
  constructor(private requestService: RequestService) {}

  getTransfers(page: number) {
    return this.requestService.post<unknown, FundsTransferListResponse>(
      'api/account/funds_transfer/list',
      {},
      { params: { page, page_size: pageSize } },
    )
  }
}
