import { JwtGroup, JwtInfo } from 'ngx-customapp-jwt'
import { TokenInfo } from 'common/models/token-info'
import { AuthResponse } from 'common/models/auth-response'

export interface CredentialsToken {
  type: 'token'
  data: JwtInfo
}

export interface CredentialsPassword {
  type: 'password'
  data: {
    login: string
    password: string
  }
}

export type Credentials = CredentialsToken | CredentialsPassword

export const authHeader = {
  name: 'Authorization',
  createValue: (jwt: JwtInfo) => `Bearer ${jwt.token}`,
}

export const tokenInfoToJwt = (tokenInfo: TokenInfo): JwtInfo => ({
  token: tokenInfo.token,
  expiresAt: tokenInfo.expires_at,
})

export const authResponseToJwt = (authResp: AuthResponse): JwtGroup<JwtInfo> | undefined => {
  if (authResp.access_token && authResp.refresh_token) {
    return {
      accessToken: tokenInfoToJwt(authResp.access_token),
      refreshToken: tokenInfoToJwt(authResp.refresh_token),
    }
  }
  return undefined
}

export const jwtToCredentials = (jwt: JwtInfo): Credentials => ({
  type: 'token',
  data: jwt,
})
