import { provideImageKitLoader } from '@angular/common'

export const cdnUrlPattern = /.*ik\.imagekit\.io.*/

export const IMAGE_BASE_URL = 'https://ik.imagekit.io/uh65fcsgs'
/**
 * See
 * https://angular.io/guide/image-directive
 */
export const ngxOptimizedImageLoaderProvider = provideImageKitLoader(IMAGE_BASE_URL)

export const toDeprecatedUrl = (
	url: string,
	width: number | null,
	quality: number = 80,
	format: string = 'webp'
): string => {
	if (!url || url.startsWith('/assets/img')) {
		return url
	}

	if (url.startsWith('https')) {
		let params = ''
		if (width != null) {
			params += `tr=w-${width}`
		}
		params += `,q-${quality}`
		params += `,f-${format}`

		return `${url}?${params}`
	}
	let crop = ''
	if (width != null) {
		crop = '?tr=w-' + width
	}

	return url.startsWith('/') ? `${IMAGE_BASE_URL}${url}${crop}` : `${IMAGE_BASE_URL}/${url}${crop}`
}
