import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { CreateTicketService } from '../api/create-ticket.service'
import { sendTicket } from './create-ticket.actions'

@Injectable()
export class CreateTicketEffects {
  constructor(private actions$: Actions, private ticketsTableService: CreateTicketService) {}

  loadTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTicket.start),
      mergeMap(({ ticketData }) =>
        this.ticketsTableService.sendTicket(ticketData).pipe(
          map(response => sendTicket.success(response)),
          catchError(error => of(sendTicket.error({ error: String(error) }))),
        ),
      ),
    ),
  )
}
