import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { kgpostLoad, kgpostLoadError, kgpostLoadSuccess } from './kgpost.actions'
import { KgPostService } from './kgpost.service'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class KgPostEffects {
	constructor(private actions$: Actions, private kgpostService: KgPostService) {}

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(kgpostLoad),
			mergeMap(({ city_id }) =>
				this.kgpostService.load(city_id).pipe(
					map(data => kgpostLoadSuccess({ data })),
					catchError(error => of(kgpostLoadError({ error })))
				)
			)
		)
	)
}
