import { createAction, props } from '@ngrx/store'
import { BoxberryOffice } from '../../../../../../../common/models/boxberry-office'
import { errorProps } from '../../../../../../../common/utils/store/types'
import { cityIdProps } from '../../../../../../../common/utils/delivery-office/types'

export const boxberryLoad = createAction('[Boxberry] load', cityIdProps)

export const boxberryLoadSuccess = createAction('[Boxberry] load success', props<{ data: BoxberryOffice[] }>())

export const boxberryLoadError = createAction('[Boxberry] load error', errorProps)

export const boxberryRemove = createAction('[Boxberry] remove')
