import { animate, keyframes, style, transition, trigger } from '@angular/animations';

export const cookieAnimation = trigger('cookieAnimation', [
	transition(':enter', [
		animate(600, keyframes([
			style({ opacity: 0, offset: 0 }),
			style({ opacity: 1, offset: 1 })
		]))
	]),
	transition(':leave', [
		animate(600, keyframes([
			style({ opacity: 1, offset: 0 }),
			style({ opacity: 0, offset: 1 })
		]))
	])
])
