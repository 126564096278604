/* eslint-disable @typescript-eslint/no-explicit-any */
const createRecord = <Id extends string | number, Key extends keyof any, T extends { [key in Key]: Id }>(
  data: T[],
  key: Key,
): Record<Id, T> => {
  const dict: Record<Id, T> = Object.create(null)
  for (const item of data) {
    dict[item[key]] = item
  }
  return dict
}

export const createOldPriceRecord = <Id extends string | number, T extends { old_price: Id }>(data: T[]) =>
  createRecord<Id, 'old_price', T>(data, 'old_price')

export const createIdRecord = <Id extends string | number, T extends { id: Id }>(data: T[]) =>
  createRecord<Id, 'id', T>(data, 'id')

export const createIsoCodeRecord = <Id extends string | number, T extends { iso_code: Id }>(data: T[]) =>
  createRecord<Id, 'iso_code', T>(data, 'iso_code')

export const createStockIdRecord = <Id extends string | number, T extends { stock_id: Id }>(data: T[]) =>
  createRecord<Id, 'stock_id', T>(data, 'stock_id')
