import { Admin } from 'common/models/admin'
import { AuthResponse } from 'common/models/auth-response'
import { unreachable } from 'ngx-customapp-errors'
import { AllUserDataResp } from '../../models/all-user-data-resp'
import { Support } from '../../models/support'
import { Datestamp } from 'common/models/datestamp'
import { DateTime } from 'luxon'

export interface UserInfoAdmin {
  role: 'admin'
  data: Admin
}

export interface UserInfoClientPartner {
  role: 'clientPartner'
  data: { userInfo: AllUserDataResp; parent: AllUserDataResp | undefined }
}

export interface UserInfoSupport {
  role: 'support'
  data: Support
}

export type UserInfo = UserInfoAdmin | UserInfoClientPartner | UserInfoSupport

export type UserRole = UserInfo['role']

export const authResponseToUserInfo = (authResponse: AuthResponse): UserInfo => {
  if (authResponse.admin) {
    return {
      role: 'admin',
      data: authResponse.admin,
    }
  } else if (authResponse.client_partner) {
    return {
      role: 'clientPartner',
      data: { userInfo: authResponse.client_partner, parent: authResponse.parent },
    }
  } else if (authResponse.support) {
    return {
      role: 'support',
      data: authResponse.support,
    }
  } else {
    return unreachable('no user in auth response')
  }
}
