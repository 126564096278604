import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { CheckoutService } from 'client/src/features/checkout/services/checkout.service'
import { catchError, debounceTime, map, mergeMap, of, tap } from 'rxjs'
import { checkPaymentStatus, payOrder, setBonusValues } from './pay.actions'
import { Router } from '@angular/router'
import { loadBonusInfo } from '../../../widgets/checkout/bonus-info-after-order/model/bonus-info.actions'
import { getCart } from '../cart/cart.actions'
import { readonlyToken } from 'client/src/features/checkout/apply-referrer-token/model/apply-token.actions'

@Injectable()
export class PayEffects {
	constructor(private actions$: Actions, private checkoutService: CheckoutService, private router: Router) {}

	payOrder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(payOrder.start),
			mergeMap(({ req }) =>
				this.checkoutService.payOrder(req).pipe(
					map(paymentOrder => payOrder.success({ paymentOrder })),
					catchError(error => of(payOrder.error({ error }), readonlyToken({ s: false })))
				)
			)
		)
	)

	refreshCartAfterPay$ = createEffect(() =>
		this.actions$.pipe(
			ofType(payOrder.success),
			mergeMap(() => of(getCart()))
		)
	)

	redirectToPay$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(payOrder.success),
				tap(({ paymentOrder }) => {
					this.router.navigate(['/checkout/pay-order', paymentOrder.id])
				})
			),
		{ dispatch: false }
	)

	checkPayment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(checkPaymentStatus.start),
			mergeMap(({ orderId }) =>
				this.checkoutService.checkPayment(orderId).pipe(
					map(status => checkPaymentStatus.success({ status })),
					catchError(error => of(checkPaymentStatus.error({ error: String(error) })))
				)
			)
		)
	)

	loadBonusInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setBonusValues),
			debounceTime(800),
			mergeMap(({ bonus, gift }) =>
				of(
					loadBonusInfo.start({
						req: {
							bonus_points_in_currency: bonus || 0,
							commodity_points_in_currency: gift || 0
						}
					})
				)
			)
		)
	)
}
