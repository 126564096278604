import { createReducer, on } from '@ngrx/store'
import { getTransferHistoryActions } from './transfer-history.actions'
import { FundsTransferListResponse } from 'common/models/funds-transfer-list-response'
import { sendBonus, withdrawBonus } from './transfer-bonus.actions'

export const featureKey = 'transfers history'

export interface State {
  loading: boolean
  response: FundsTransferListResponse | null
}

export const initialState: State = {
  loading: false,
  response: null,
}

export const reducer = createReducer(
  initialState,
  on(getTransferHistoryActions.start, state => {
    return { ...state, loading: true }
  }),
  on(getTransferHistoryActions.success, (state, response) => {
    return { ...state, response, loading: false }
  }),
  on(getTransferHistoryActions.error, state => {
    return { ...state, loading: false }
  }),

  on(sendBonus.success, (state, { transfer }) => {
    return {
      loading: state.loading,
      response: {
        ...state.response,
        transfers: [transfer, ...(state.response?.transfers ?? [])],
      },
    }
  }),

  on(withdrawBonus.success, (state, { transfer }) => {
    return {
      loading: state.loading,
      response: {
        ...state.response,
        transfers: [transfer, ...(state.response?.transfers ?? [])],
      },
    }
  }),
)
