import { Injectable } from '@angular/core'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private requestService: RequestService) {}

  sendSMSForPasswordRecover(phone: string) {
    return this.requestService.post<{ phone: string }, void>('/api/account/pass_recovery', { phone })
  }
}
