import { createReducer, on } from '@ngrx/store'
import * as eventsActions from './events.actions'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Event } from 'common/models/event'

const selectEventId = (event: Event) => event.id ?? -1

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>({ selectId: selectEventId })

export const { selectAll } = adapter.getSelectors()

export const featureKey = 'eventItem'

export interface State extends EntityState<Event> {
  loadingEventItem: boolean
  currentEventItem: Event | null
  prevEventId: null,
  nextEventId: null,
}

export const initialState: State = adapter.getInitialState({
  loadingEventItem: false,
  currentEventItem: null,
  prevEventId: null,
  nextEventId: null,
})

export const reducer = createReducer(
  initialState,
  on(eventsActions.getEventItem.start, state => ({ ...state, loadingEventItem: true })),
  on(eventsActions.getEventItem.success, (state, { event }) => ({
    ...state,
    currentEventItem: event,
    loadingEventItem: false
  })),
  on(eventsActions.getEventItem.error, state => ({ ...state, loadingEventItem: false })),
)
