import { Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonComponent } from '../../ui/button/button.component'
import { DialogBodyComponent } from '../../ui/dialog/dialog-body/dialog-body.component'
import { DialogFooterComponent } from '../../ui/dialog/dialog-footer/dialog-footer.component'
import { DialogHeaderComponent } from '../../ui/dialog/dialog-header/dialog-header.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'app-success-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    DialogBodyComponent,
    DialogFooterComponent,
    DialogHeaderComponent,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent {
  constructor(
    public dialogRef: MatDialogRef<SuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { description?: string },
  ) {}

  close(): void {
    this.dialogRef.close()
  }
}
