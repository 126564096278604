import { Injectable } from '@angular/core'
import { Config } from 'common/models/config'
import { Currency } from 'common/models/currency'
import { Country } from 'common/models/country'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(private requestService: RequestService) {}

  loadCurrencies() {
    return this.requestService.post<unknown, Currency[]>('/api/currencies', {})
  }

  loadCountries() {
    return this.requestService.post<unknown, Country[]>('/api/cart/available_countries', {})
  }

  loadConfig() {
    return this.requestService.post<unknown, Config>('/api/config/get', {})
  }
}
