import { createReducer, on } from '@ngrx/store'
import { loadTickets } from './tickets-table.actions'
import { TicketsListResponse } from 'common/models/tickets-list-response'

export const featureKey = 'tickets table'

export interface State {
  loading: boolean
  response: TicketsListResponse | null
}

export const initialState: State = {
  loading: false,
  response: null,
}

export const reducer = createReducer(
  initialState,
  on(loadTickets.start, state => {
    return { ...state, loading: true }
  }),
  on(loadTickets.success, (state, response) => {
    return { ...state, response, loading: false }
  }),
  on(loadTickets.error, state => {
    return { ...state, loading: false }
  }),
)
