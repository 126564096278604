import { on, createReducer } from '@ngrx/store'
import {
  loadCities,
  loadCountries,
  loadRegions,
  removeCities,
  removeCountries,
  removeRegions,
} from './geo-search.actions'
import { Country } from 'common/models/country'
import { Region } from 'common/models/region'
import { City } from 'common/models/city'

export const featureKey = 'geo-search'

export interface State {
  isLoadingCountries: boolean
  isLoadingRegions: boolean
  isLoadingCities: boolean

  countries: Country[] | null
  regions: Region[] | null
  cities: City[] | null
}

const initialState: State = {
  isLoadingCountries: false,
  isLoadingRegions: false,
  isLoadingCities: false,
  countries: null,
  regions: null,
  cities: null,
}

export const reducer = createReducer(
  initialState,
  on(loadCountries.start, state => ({ ...state, isLoadingCountries: true })),
  on(loadCountries.success, (state, { countries }) => ({ ...state, countries, isLoadingCountries: false })),
  on(loadCountries.error, state => ({ ...state, isLoadingCountries: false })),

  on(loadRegions.start, state => ({ ...state, isLoadingRegions: true })),
  on(loadRegions.success, (state, { regions }) => ({ ...state, regions, isLoadingRegions: false })),
  on(loadRegions.error, state => ({ ...state, isLoadingRegions: false })),

  on(loadCities.start, state => ({ ...state, isLoadingCities: true })),
  on(loadCities.success, (state, { cities }) => ({ ...state, cities, isLoadingCities: false })),
  on(loadCities.error, state => ({ ...state, isLoadingCities: false })),

  on(removeCountries, state => ({ ...state, countries: null })),
  on(removeRegions, state => ({ ...state, regions: null })),
  on(removeCities, state => ({ ...state, cities: null })),
)
