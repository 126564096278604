import { Injectable } from '@angular/core'
import { RequestService } from '../../../../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { RuPostOffice } from '../../../../../../../common/models/ru-post-office'

@Injectable({
  providedIn: 'root',
})
export class RupostService {
  constructor(private request: RequestService) {}

  load(city_id: number): Observable<RuPostOffice[]> {
    return this.request.post('/api/delivery/rupost_offices', undefined, {
      params: {
        city_id,
      },
    })
  }
}
